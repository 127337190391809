import { useTranslation } from "react-i18next";
import Modal from "../Modal"
import { renderWithLineBreaks } from "../../utils";
import imageSrc from '../../images/feedback-thanks.webp';

export const FeedbackModal = ({ opened, onClose }) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={opened} onClose={onClose} contentClassName="min-h-[477px] relative flex flex-col justify-end">
            <div className="w-full h-full p-[30px_36px_30px_46px] flex flex-col ">

                <img src={imageSrc} className="w-[125px] h-[150px] self-center" alt="" />
                <div className="text-[#242a46] text-[18px] font-[500] text-center mt-[21px]">
                    {renderWithLineBreaks(t('FEEDBACK_DONE_TITLE'))}
                </div>

                <button onClick={onClose} className="mt-[43px] cursor-pointer text-[#ffffff]  bg-[#0076ff] text-[20px] font-bold w-full flex justify-center items-center rounded-[16px] h-[64px] ">
                    {renderWithLineBreaks(t('FEEDBACK_DONE_BUTTON'))}
                </button>
            </div>
        </Modal>
    )
}