import { useMemo } from "react"
import '../../styles/Animation.css';

export const Dots = ({ amount, selectedIndex }) => {

    const elements = useMemo(() => {
        const arr = new Array(amount).fill(0)

        const elems = arr.map((_, index) => {
            const selected = selectedIndex === index;
            const closer = index === (selectedIndex - 1) || index === (selectedIndex + 1)

            return (<div key={index} className={`dots-size rounded-[50%] bg-black w-1 h-1 ${selected ? 'scale-[2]' : ''} ${closer ? 'scale-150' : ''}`}></div>)
        })

        return elems;
    }, [amount, selectedIndex])

    return (
        <>
            {elements}
        </>
    )
}