import { useCallback, useEffect, useState } from "react";
import { BackIcon } from "../icons"
import { useHistory, useParams } from "react-router-dom";
import { isTelegram, renderWithLineBreaks } from "../utils";
import { makeRequest } from "../utils/request";
import { useTranslation } from "react-i18next";
import { FeedbackModal } from "../components/ModalFeedback";

export const FeedbackPage = () => {

    let { photoId } = useParams();

    const history = useHistory();
    const [feedbackValue, setFeedbackValue] = useState('');
    const [FeedbackModalOpened, setFeedbackModalOpened] = useState(false);

    const { t } = useTranslation();

    const closeFeedbackModal = useCallback(() => {
        setFeedbackModalOpened(false);
    }, []);

    const onSubmitFeedback = useCallback(() => {
        makeRequest('/score/feedback', 'post', {
            score_id: photoId,
            message: feedbackValue,
        }, {}, false);
        
        setFeedbackModalOpened(true)
        setFeedbackValue('');
    }, [feedbackValue, photoId])

    const onChangeFeedback = useCallback((e) => {
        if (e?.target?.value !== undefined) {
            setFeedbackValue(e.target.value)
        } else {
            setFeedbackValue('')
        }
    }, [])

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', isTelegram() ? '#FFFFFF' : '#f6f7f8');
        document.querySelector('body').style = 'background-color: #f6f7f8'
    }, [])

    const goBack = useCallback(() => {
        history.goBack();
    }, [history])

    return (
        <div className="w-full bg-[#f6f7f8] flex justify-center pb-[75px]">
            <div className="w-full max-w-[335px]">
                <div className="flex ">
                    <button onClick={goBack} className="bg-[#b6b9c2] rounded-[50%] mt-[20px] ml-[25px] self-start flex justify-center items-center w-[38px] h-[38px]">
                        <BackIcon />
                    </button>
                </div>

                <textarea value={feedbackValue} onChange={onChangeFeedback} className="border border-solid border-[#B6B9C2] placeholder-[#B6B9C2] focus-visible:outline-[#0076ff] w-full mt-[16px] rounded-[16px] h-[240px] bg-[#f4f5f8] py-[18px] px-[16px] text-[#242a46] text-[16px]" placeholder={t('FEEDBACK_INPUT_HOVER')}>
                </textarea>

                <button onClick={onSubmitFeedback} className="mt-[18px] cursor-pointer text-[#ffffff]  bg-[#0076ff] text-[20px] font-[600] w-full flex justify-center items-center rounded-[16px] h-[64px] ">
                    {renderWithLineBreaks(t('FEEDBACK_BUTTON'))}
                </button>

            </div>
            <FeedbackModal opened={FeedbackModalOpened} onClose={closeFeedbackModal} />
        </div>
    )
}