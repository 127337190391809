import { useCallback, useEffect, useState } from "react";
import { makeRequest } from "../utils/request";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { isTelegram } from "../utils";
import { Loader } from "../components/Loader";
import { Analysis } from "../components/Analysis";
import { TipsModal } from "../components/ModalTips";

export const AnalysisPage = () => {

    const history = useHistory();

    let { photoId } = useParams();
    const [photos, setPhotos] = useState([]);
    const [analysis, setAnalysis] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [opened, setOpenModal] = useState(false);

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', isTelegram() ? '#FFFFFF' : '#f6f7f8');
    }, [])

    useEffect(() => {
        if (photoId) {
            makeRequest('/score/analysis', 'post', {
                score_id: photoId
            }, {}, false).then((response) => {
                if (response.data.status) {
                    setPhotos(response.data.result.selfies);
                    setAnalysis(response.data.result.analysis);
                    setIsLoading(false);
                }
            })
        }
    }, [photoId])

    const onGoBack = useCallback(() => {
        history.push(`/${photoId}`);
    }, [photoId, history])

    const onClickTips = useCallback(()=>{
        // setOpenModal(true);
        // history.push(`/pay-tips/${photoId}`);
        alert(`This method isn't implemented yet`);
    }, [history, photoId])

    const closeModal = useCallback(()=>{
        setOpenModal(false) 
    }, [])

    return (
        <>
            {isLoading ? <div>
                <Loader />
            </div> : <Analysis
                onClickTips={onClickTips}
                analysis={analysis}
                photos={photos}
                goBack={onGoBack}
            />}
            <TipsModal opened={opened} onClose={closeModal} />
        </>
    )
}