import { useCallback, useReducer } from "react";
import { makeRequest } from "../../utils/request";
import Modal from "../Modal";
import { LoadingIcon } from "../../icons";
import "../../styles/Animation.css";
import loadMoreSrc from '../../images/load-more-example.webp';
import loadLeftSrc from '../../images/example-load-left.webp';
import loadRightSrc from '../../images/example-load-right.webp';
import { PhotoLoading } from "./PhotoLoading";
import { useTranslation } from "react-i18next";
import { PhotoLoadingError } from "./PhotoError";
import { loadResultScore, renderWithLineBreaks } from "../../utils";



const initialState = {
    photoLoaded: {
        id: '',
        url: '',
    },
    photoError: {
        text: '',
        url: '',
    },

    loading: false
}

const getStage = (state) => {

    if (state.loading) {
        return 'loading';
    }

    if (state.photoError.url) {
        return 'image_error';
    }

    if (!state.photoLoaded.id) {
        return 'loadPhoto';
    }


}

function reducer(state, action) {
    if (action.type === 'reset') {
        return initialState;
    }

    if (action.type === 'start_loading') {
        return {
            ...state,
            loading: true,
            loadingHeader: action.header,
            loadingText: action.text,
        }
    }

    if (action.type === 'photo_loaded') {
        return {
            ...state,
            photoLoaded: {
                id: action.id,
                url: action.url,
            },
            photoError: {
                text: '',
                url: '',
            }
        }
    }
    let modifiedState = { ...state, loading: false, };
    if (action.type === 'image_error') {
        return {
            ...modifiedState,
            photoError: {
                text: action.text,
                url: action.url,
            }
        }
    }

    return modifiedState;
}

export const PhotoLoadingModal = ({ opened, onClose, scoreId, mode = 'left' }) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const { t } = useTranslation();

    const closeHandler = useCallback(() => {
        dispatch({
            type: 'reset'
        });
        onClose();
    }, [onClose])

    const stage = getStage(state);

    const loadPhoto = useCallback(async (photoBlob, photoSrc) => {
        dispatch({
            type: 'start_loading',
            header: t('LOADING_UPLOAD_TITLE'),
            text: t('LOADING_UPLOAD_SUBTITLE')
        });
        // some async code
        const formData = new FormData();
        formData.append('file', photoBlob)
        formData.append('selfie_type', mode === 'left' ? 3 : 4)
        const result = await makeRequest(`/selfie/upload`, 'post', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (!result.data.status || result.data.result.moderation.status !== 1) {
            if (result.data.status && result.data.result.moderation.status === 0)
                dispatch({
                    type: 'image_error',
                    text: result.data.message || result.data.result?.moderation?.comment || 'Internet Error',
                    url: result.data?.result?.url || photoSrc,
                })
            return;
        }
        try {
            const creationResult = await makeRequest(`/score/update`, 'post', {
                [mode === 'left' ? 'selfie_side_left_id' : 'selfie_side_right_id']: result.data.result.selfie_id,
                score_id: scoreId,
            });

            await makeRequest('/score/advanced/create', 'post', {
                score_id: scoreId,
            });
            dispatch({
                type: 'start_loading',
                header: t('LOADING_SCORE_ADVANCED_TITLE'),
                text: t('LOADING_SCORE_ADVANCED_SUBTITLE')
            });

            loadResultScore(scoreId, 'none').then(() => {
                window.location.reload();
            })
        } catch (e) {
            console.error(e);
            dispatch({
                type: 'image_error',
                text: e.message || 'Internet Error',
                url: result.data?.result?.url || photoSrc,
            })
        }

    }, [scoreId, mode, t])


    const renderContent = (state) => {
        const stage = getStage(state);
        switch (stage) {
            case 'loading':
                const headerText = state.loadingHeader;
                const text = state.loadingText;
                return (
                    <div className=" absolute top-[calc(50%-13px)] ">
                        <LoadingIcon className="loader absolute left-[calc(50%-12px)]" />
                        {
                            headerText && <>
                                <div className='text-[38px] mt-[55px] font-[500] text-[#232948] leading-[0.63] text-center'>
                                    {renderWithLineBreaks(headerText)}
                                </div>

                                <div className='text-[16px] mt-[16px] text-[#70777f] text-center leading-[1.5]'>
                                    {renderWithLineBreaks(text)}
                                </div>
                            </>
                        }
                    </div>

                )
            case 'loadPhoto':
                return (
                    <PhotoLoading
                        onPhotoLoaded={loadPhoto}
                        text={mode === 'left' ? 'Upload Your \nLeft Side Selfie' : 'Upload Your \nRight Side Selfie'}
                        exampleSrc={mode === 'left' ? loadLeftSrc : loadRightSrc}
                    />
                )
            case 'image_error':
                return (
                    <PhotoLoadingError onPhotoLoaded={loadPhoto} text={state.photoError.text} exampleSrc={state.photoError.url} />
                )
            default:
                return (
                    <div className="self-center">
                        <LoadingIcon className="loader" />
                    </div>
                )
        }
    }

    return (
        <Modal isOpen={opened} onClose={closeHandler} hideCloseButton={stage === 'loading'} contentClassName="min-h-[517px] relative">
            <div className="flex w-full justify-center">
                <div className="w-[241px] flex flex-col pt-[40px] items-center pb-[30px]">
                    {renderContent(state)}
                </div>
            </div>
        </Modal>
    )
}