import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLocale from './locales/en.json';
import Backend from 'i18next-http-backend';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: enLocale
    },
};

i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: "en",
        fallbackLng: false,
        backend: {
            loadPath: (lngs, namespaces) => {
                let lng = lngs[0];
                if (lng === 'dev') {
                    lng = 'en';
                }
                return `${process.env.REACT_APP_API_LINK}/localization/`;
            },
            parse: function (data) {
                const loadedTranslations = JSON.parse(data);
                // const localFile = enLocale;

                // for (let key in localFile) {
                //     if (!loadedTranslations[key]) {
                //         loadedTranslations[key] = localFile[key];
                //     }
                // }
                return loadedTranslations;
            },
        }
    },
    );

export default i18n;