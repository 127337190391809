import React, { useCallback } from "react";
import { BackIcon } from "../icons";
import { makeRequest } from "../utils/request";

export const Analysis = ({ goBack, photos, analysis, onClickTips }) => {
    const onGetTips = useCallback((value) => {
        makeRequest('/user/update', 'post', {
            tap: value
        });
        onClickTips()
    }, [onClickTips]);
    return (
        <div className="w-full bg-[#f6f7f8] flex justify-center p-4 pb-[75px]">
            <div className="w-full max-w-[335px] ">

                {/* Back Button */}
                <button onClick={goBack} className="bg-[#b6b9c2] rounded-[50%] mt-[20px] ml-[25px] self-start flex justify-center items-center w-[38px] h-[38px]">
                    <BackIcon />
                </button>

                {/* Image & Text Section */}
                <div className="mt-[16px] rounded-[16px] w-full flex flex-col items-center">
                    <div className="w-full">
                        {/* One Photo Layout */}
                        <img
                            src={photos[0]}
                            alt=""
                            className="rounded-[16px] w-[335px]"
                        />
                    </div>
                </div>
                <div className="mt-[19px]">
                    {
                        analysis.map(({ caption, description, score, value }) => {
                            return (
                                <div key={caption} className=" border-[0.5px] border-solid border-[#c3cede] w-full mt-[10px]  p-[11px_0px] rounded-[16px] bg-white relative">
                                    <div className="ml-[19px] mr-[18px]">
                                        <div className="font-text font-[600] text-[#888] leading-[1.47] text-[15px]">{caption}</div>
                                        <div className="text-[#242a46] font-text leading-[1.33] mt-[3px] text-[15px]">
                                            {description}
                                        </div>
                                    </div>
                                    <div className="flex justify-start mt-[7px] ml-[16px] mr-[18px]">
                                        <div className=" w-[93px] bg-[#f2f2f2] h-[30px] flex justify-center items-center rounded-[15px] text-[#242a46] font-text leading-[1.33] text-[15px]">
                                            {score}
                                        </div>
                                        {/* <button onClick={() => { onGetTips(value) }} className="h-[30px] flex justify-center items-center text-[#0076ff] font-text leading-[1.33] text-[15px] border-0 ">
                                            Analysis
                                        </button> */}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};
