import React, { useState, useEffect, useRef, useCallback } from 'react';
import { twMerge } from 'tailwind-merge'

const SimpleCarousel = ({ children, onChangeCurrentIndex, containerClassName }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const timeoutRef = useRef(null);

    const handleNext = useCallback(() => {
        if (!isAnimating) {
            setIsAnimating(true);
            setCurrentIndex((prevIndex) => (prevIndex + 1) % children.length);
        }
    }, [isAnimating, children.length])

    const handlePrev = useCallback(() => {
        if (!isAnimating) {
            setIsAnimating(true);
            setCurrentIndex(
                (prevIndex) => (prevIndex - 1 + children.length) % children.length
            );
        }
    }, [isAnimating, children.length]);

    useEffect(() => {
        onChangeCurrentIndex(currentIndex);
    }, [currentIndex, onChangeCurrentIndex])

    const handleSwipe = useCallback((direction) => {
        if (direction === 'left') handleNext();
        else if (direction === 'right') handlePrev();
    }, [handleNext, handlePrev]);


    const handleTouchStart = useCallback((e) => {
        const touchStartX = e.touches[0].clientX;
        const listener = (ev) => {
            const touchEndX = ev.changedTouches[0].clientX;
            const swipeDirection =
                touchStartX - touchEndX > 30
                    ? 'left'
                    : touchEndX - touchStartX > 30
                        ? 'right'
                        : null;
            e.target.removeEventListener('touchend', listener);
            if (swipeDirection) handleSwipe(swipeDirection);
        };
        e.target.addEventListener('touchend', listener);
    }, [handleSwipe])

    const handleAnimationEnd = () => {
        setIsAnimating(false);
    };

    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            setIsAnimating(false);
        }, 500);

        return () => clearTimeout(timeoutRef.current);
    }, [currentIndex]);

    return (
        <div
            className={twMerge(containerClassName, "carousel-container")}
            onClick={handleNext}
            onAnimationEnd={handleAnimationEnd}
            onTouchStart={handleTouchStart}
        >
            {children.map((child, index) => (
                <div
                    key={index}
                    className={`carousel-item ${currentIndex === index ? 'shown' : 'non-visible'
                        }`}
                >
                    {child}
                </div>
            ))}
            <style jsx="true">{`
        .carousel-container {
          overflow: hidden;
          cursor: pointer;
          position: relative;
          width: 335px;
          display: flex;
          justify-content: center;
        }
        .carousel-item {
          opacity: 0;
          transition: opacity 1s ease-in-out;
          position: absolute;
          top: 0;
        }
        .carousel-item.shown {
          opacity: 1;
          z-index: 1;
          visibility: visible;
        }
        .carousel-item.non-visible {
          opacity: 0;
          z-index: 0;
          visibility: hidden;
        }
      `}</style>
        </div>
    );
};

export default SimpleCarousel;