import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useHistory } from 'react-router-dom';
import { IconBackBlue, IconForwardGray, IconLock } from '../icons';
import { Loader } from '../components/Loader';
import { makeRequest } from '../utils/request';
import { renderWithLineBreaks } from '../utils';
import { ModalListPaywall } from '../components/ModalListPaywall';

export const ScoresPage = () => {

    const { t } = useTranslation();
    let { photoId, mode } = useParams();

    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory();
    const [items, setItems] = useState([])
    const [activeItem, setActiveItem] = useState(null);

    const [paywallModalOpened, setPaywallModalOpened] = useState(false);

    const openModal = useCallback(() => {
        setPaywallModalOpened(true);
    }, [])

    const closeModal = useCallback(() => {
        setPaywallModalOpened(false);
    }, [])

    useEffect(() => {
        makeRequest('/score/advanced/list', 'post', {
            score_id: photoId,
        }).then((res) => {
            if (res.data.status) {
                if (mode === 'ratings') {
                    setItems(res.data.result.ratings);
                } else {
                    setItems(res.data.result.features);
                }
                setIsLoading(false);
            }
        });
    }, [photoId, mode])

    const onGoDetails = useCallback((item) => {
        if (item.status === 1) {
            history.push(`/scores/${photoId}/${mode}/${item.field}/${item.caption}`);
        } else {
            setActiveItem(item);
            openModal();
        }

    }, [history, photoId, mode, openModal]);

    const onPayModal = useCallback(() => {
        const item = activeItem;
        window.dataLayer.push({ 'event': 'unlock' });
        history.push(`/scores/${photoId}/${mode}/${item.field}/${item.caption}`);
        setActiveItem(null);
    }, [activeItem, history, mode, photoId])

    const convertedItems = useMemo(() => {
        return items.slice(0, 4).map((elem) => {
            return {
                ...elem,
                score: elem.value
            }
        })
    }, [items])

    return (
        <>
            {isLoading ? <div>
                <Loader />
            </div> : <div className='bg-white  min-h-screen  flex flex-col items-center'>
                <div className="max-w-[335px] w-full pt-[59px]">
                    <div className="text-[#0073ff] text-[18px]">
                        <Link to={`/${photoId}`} className="flex items-center">
                            <IconBackBlue />
                            <span className='inline-block ml-[7px]'>{renderWithLineBreaks(t('LIST_BACK'))}</span>
                        </Link>
                    </div>
                    <div className="text-[30px] font-bold text-[#232948] mt-[15px] leadinf-[1.23]">
                        {mode === 'ratings' ?
                            renderWithLineBreaks(t('SCORE_RATINGS_LIST_TITLE'))
                            :
                            renderWithLineBreaks(t('SCORE_FEATURES_LIST_TITLE'))
                        }
                    </div>
                    <div className="mt-[23px]">
                        {items.map((item) => (
                            <div
                                onClick={() => { onGoDetails(item) }}
                                key={item.caption}
                                className="flex justify-between h-[71px] items-center border-b border-[#f2f2f2] text-[18px] font-[600] text-[#232948]"
                            >
                                <span>{item.caption}</span>
                                <div className="text-[#0076ff] flex gap-[20px] items-center  text-[18px] font-[500]">
                                    {item.status === 1 ? <>
                                        {item.value || ''}
                                        <IconForwardGray />
                                    </> : <IconLock />}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <ModalListPaywall mode={mode} opened={paywallModalOpened} onClose={closeModal} items={convertedItems} onPay={onPayModal} />
            </div>}
        </>
    );
};

