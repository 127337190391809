import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Loader } from "../components/Loader";
import { IconBackBlue } from "../icons";
import { renderWithLineBreaks } from "../utils";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { makeRequest } from "../utils/request";

export const DetailsPage = () => {

    // /score/details
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [result, setResult] = useState({});
    let { photoId, fieldId, mode, fieldName } = useParams();

    useEffect(() => {
        makeRequest('/score/advanced/details', 'post', {
            score_id: photoId,
            field: fieldId,
            caption: fieldName,
        }).then((res) => {
            if (res.data.status) {
                setResult(res.data.result);
                setIsLoading(false);
            }
        })
    }, [photoId, mode, fieldId, fieldName])

    return (
        <>
            {isLoading ? <div>
                <Loader />
            </div> : <div className='bg-white  min-h-screen  flex flex-col items-center'>
                <div className="max-w-[335px] w-full pt-[59px]">
                    <div className="text-[#0073ff] text-[18px]">
                        <Link to={`/scores/${photoId}/${mode}`} className="flex items-center">
                            <IconBackBlue />
                            <span className='inline-block ml-[7px]'>{renderWithLineBreaks(mode === 'features' ? t('SCORE_FEATURES_LIST_TITLE') : t('SCORE_RATINGS_LIST_TITLE'))}</span>
                        </Link>
                    </div>
                    <div className="text-[30px] font-bold text-[#232948] mt-[15px] leadinf-[1.23]">
                        {result.title}
                    </div>

                    <div className="mt-[22px] max-w-[327px] text-[18px] leading-[1.22] text-[#242a46] tracking-[normal]">
                        {renderWithLineBreaks(result.description)}
                    </div>
                    {(result.score || result.value) && <div className="bg-[#f2f2f2] mt-[20px] w-[93px] h-[30px] text-[#242a46] text-[15px] rounded-[15px] flex items-center justify-center">
                        {
                            result.score ? <>
                                Score: {result.score}
                            </> :
                                <>
                                    {result.value}
                                </>
                        }

                    </div>}
                </div>
            </div>}
        </>
    )
}