import { useEffect } from "react"
import { useHistory } from "react-router-dom"

export const ErrorCallbackPage = () => {

    const history = useHistory();

    useEffect(() => {
        history.push('/')
    })

    return (
        <>
        </>
    )
}