import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import './styles.css';
import { Dots } from '../Carousel/Dots';
// Swiper.use([Navigation, Pagination]);

const CarouselWithCenteredSlide = ({ slides }) => {

    const [index, setIndex] = useState(0);
    return (
        <>
            <Swiper
                width={320}
                className='!ml-[-40px] overflow-hidden'
                modules={[Pagination, Navigation]}
                spaceBetween={16} // Space between slides
                slidesPerView={'auto'} // Allow automatic width for slides
                centeredSlides={true} // Center the active slide
                onSlideChange={(e) => { setIndex(e.realIndex); }}
                loop={false} // Enable looping if needed
                cssMode={false}
                mousewheel={{
                    forceToAxis: true,
                    thresholdDelta: 35,
                }}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={index} style={{ width: '241px', height: '324px' }}>
                        {slide}
                    </SwiperSlide>
                ))}
            </Swiper >
            <div style={{ width: `${slides.length * 12}px` }} className=' mt-[16px] flex justify-between items-center '>
                <Dots amount={slides.length} selectedIndex={index} />
            </div>
        </>
    );
};

export default CarouselWithCenteredSlide;