import { useCallback, useEffect, useState } from "react"
import { makeRequest } from "../../utils/request";

import '../../styles/Animation.css';
import { useHistory } from "react-router-dom";

export const ReferalBlock = () => {
    const [peopleAmount, setPeopleAmount] = useState(56);
    const history = useHistory();
    useEffect(() => {
        const testInterval = window.setInterval(() => {
            const newAmount = peopleAmount + randomIntFromInterval(0, 3);
            setPeopleAmount(newAmount);
        }, [1000])

        return () => {
            window.clearInterval(testInterval)
        }
    }, [peopleAmount])

    const onClickReferal = useCallback(() => {
        makeRequest('/user/update', 'post', {
            tap: 'referal'
        });
        setTimeout(() => {
            history.push('/')
        }, 200)

    }, [history])

    return (
        <>
            <div className="w-full max-w-[335px] mt-[40px] text-[16px] font-bold text-[#232948] text-center">
                👇 {peopleAmount} people just tapped the button 👇
            </div>

            <button onClick={onClickReferal} className='shaking-animations flex mt-[10px] font-[600] max-w-[290px] w-full h-[60px] text-[18px] text-[white] justify-center items-center bg-[#0076ff] rounded-[16px]'>
                Get You Own Score!
            </button>
        </>
    )
}

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
}