import { useCallback, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Loader } from "../components/Loader";
import { BackIcon } from "../icons";
import { makeRequest } from "../utils/request";
import { isTelegram } from "../utils";

export const HistoryPage = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [photos, setPhotos] = useState([])

    const goBack = useCallback(() => {
        history.push('/');
    }, [history])

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', isTelegram() ? '#FFFFFF' : '#f6f7f8');
        document.querySelector('body').style = 'background-color: #f6f7f8'

        makeRequest('/user/history', 'post', {}).then((response) => {
            if (response.data.status) {
                setIsLoading(false);
                setPhotos(response.data.result.history);
            }
        });
    }, [])

    return (
        <>
            {isLoading ? <div>
                <Loader />
            </div> : <div className="w-full bg-[#f6f7f8] flex justify-center p-4 pb-[75px]">
                <div className="w-full max-w-[335px] ">
                    <button onClick={goBack} className="bg-[#b6b9c2] rounded-[50%] mt-[20px] ml-[25px] self-start flex justify-center items-center w-[38px] h-[38px]">
                        <BackIcon />
                    </button>

                    <div className="w-full flex gap-[14px] mt-[16px] flex-wrap">
                        {/* Two Photos Layout */}
                        {photos.map((photo) => (
                            <Link key={photo.score_id} to={`/${photo.score_id}`}>
                                <img

                                    src={photo.photo_url}
                                    alt=""
                                    className="rounded-[16px] h-[160px]"
                                />
                            </Link>
                        ))}
                    </div>

                </div>
            </div>
            }
        </>
    )
}