export const Bullet = ({ bgColor, offset, text }) => {
    return (
        <div className="flex flex-col items-center w-full" style={{ marginTop: `${offset}px` }}>
            <div className="w-[2px] h-[25px]" style={{ backgroundColor: bgColor }}></div>

            <div className="w-[24px] h-[24px] text-[12px] flex justify-center items-center text-[#fff] rounded-[50%]" style={{ backgroundColor: bgColor }}>
                <div className="ml-[-1px]">{text}</div>
            </div>
        </div>
    )
}
