import { useTranslation } from "react-i18next"
import { renderWithLineBreaks } from "../../utils"
import { GoogleIcon } from "../../icons";
import { useCallback, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";


const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const Auth = ({ onGetEmail }) => {

    const { t } = useTranslation();
    const [emailText, setEmailText] = useState('');

    const [isError, setIsError] = useState(false);

    const getEmailFromGoogleLogin = useCallback((response) => {
        if (response.access_token) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${response.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    onGetEmail(res.data.email);
                })
        }
    }, [onGetEmail])


    const onClickGoogle = useGoogleLogin({
        onSuccess: getEmailFromGoogleLogin,
        onError: (error) => console.error('Login Failed:', error)
    });


    const onChangeInput = useCallback((e) => {
        setEmailText(e?.target?.value || '');
        setIsError(false)
    }, [])

    const onClickNext = useCallback(() => {
        if (!emailText) {
            setIsError(true)
            return;
        }

        if (!emailRegex.test(emailText.trim())) {
            setIsError(true)
            return;
        }

        onGetEmail(emailText)
    }, [emailText, onGetEmail])

    return (
        <>
            <div className="text-[26px] font-[600] text-[#232948] leading-[normal] text-center">
                {renderWithLineBreaks(t('AUTH_TITLE'))}
            </div>

            <div className="w-[240px] h-[64px] self-center mt-[21px] ">
                <input
                    onChange={onChangeInput}
                    placeholder={t('AUTH_EMAIL_HOVER')}
                    className={twMerge("w-full px-[19px] h-full focus:outline-none rounded-[16px] border-[1px] border-solid border-[rgba(35,41,72,0.5)] text-[#242a46] text-[16px]", isError ? 'border-[#DB2222]' : '')}
                />
            </div>

            <button onClick={onClickNext} className="w-full max-w-[240px] text-[white] h-[64px] mt-[10px] text-[20px] leading-[normal] tracking-[normal] font-[600] rounded-[16px] flex justify-center items-center bg-[#0175ff]">
                {renderWithLineBreaks(t('AUTH_EMAIL_BUTTON'))}
            </button>

            <div className="mt-[10px] w-[260px] self-center  text-[#242a46] text-[14px] opacity-80 text-center leading-[1.29]">
                {renderWithLineBreaks(t('AUTH_EMAIL_NOTICE'))}
            </div>

            <div className="mt-[23px] self-center w-[240px] flex items-center">
                <div className="w-[50%] h-[1px] bg-[#232948]"></div>
                <div className="text-[19px] text-[#232948] px-[10px] w-[50%]"> {renderWithLineBreaks(t('AUTH_SEPARATOR'))} </div>
                <div className="w-[50%] h-[1px] bg-[#232948]"></div>
            </div>

            <button onClick={onClickGoogle} className="w-full max-w-[240px] text-[#000] h-[64px] mt-[21px] text-[20px] leading-[normal] tracking-[normal] font-[700] rounded-[16px] flex justify-center items-center bg-[#f4f5f8]">
                <GoogleIcon className="mr-[10px]" />
                {renderWithLineBreaks(t('AUTH_GOOGLE_BUTTON'))}
            </button>

            <div className="mt-[13px] w-[260px] self-center  text-[#242a46] text-[14px] opacity-80 text-center leading-[1.29]">
                {renderWithLineBreaks(t('AUTH_GOOGLE_NOTICE'))}
            </div>
        </>
    )
}