import { useTranslation } from "react-i18next"
import { renderWithLineBreaks } from "../../utils"

export const ButtonElem = ({ onClick, text, helpText }) => {

    return (
        <>
            <button onClick={onClick} className="w-full max-w-[290px] text-[white] h-[60px] mt-[10px] text-[20px] leading-[normal] tracking-[normal] font-[600] rounded-[16px] flex justify-center items-center bg-[#0175ff]">
                {text}
            </button>

            <div className="mt-[10px]  text-[#242a46] text-[14px] opacity-80 text-center leading-[1.29]">
                {helpText}
            </div>
        </>
    )
}