import { ScoreBlock } from "../components/NewMainPage/ScoreImage";
import { BlockIcon1, BlockIcon2, BlockIcon3, BlockIcon4, CheckIcon, CrossIcon, LogoIcon } from "../images/new-main-page";
import resultScoreSrc from '../images/new-main-page/result-example.png';
import personSrc from '../images/new-main-page/person-photo.png';
import imageCenterSrc from '../images/new-main-page/image-center.png';
import imageLeftSrc from '../images/new-main-page/image-left.png';
import imageRightSrc from '../images/new-main-page/image-right.png';
import imageBorder from '../images/new-main-page/carousel-border.png';

import { Bullet } from "../components/NewMainPage/Bullet";
import facebookSrc from '../images/new-main-page/facebook.png';
import youtubeSrc from '../images/new-main-page/youtube.png';
import xSrc from '../images/new-main-page/x.png';
import lndinSrc from '../images/new-main-page/lndin.png';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export function NewMainPage({ onClickLoadingElem }) {
    return (

        <div className="w-full min-w-[375px] flex flex-col items-center">

            <div className="w-full max-w-[375px] flex flex-col items-center">
                <div className="bg-[#F1F6FF] pt-[40px] pb-[29px] w-full max-w-[375px] flex flex-col">
                    <div className="flex mx-[22px] justify-between">
                        <LogoIcon />

                        <button className="bg-[#586BFF] px-[16px] py-[10px] rounded-[48px] text-[14px] font-bricolage text-[#fff] font-[500]">
                            Sign In
                        </button>
                    </div>

                    <div className="h-[260px] mt-[22px] w-full flex items-center gap-[8px] overflow-hidden">
                        <img src={imageLeftSrc} alt="" className="w-[218px] h-[260px] ml-[-147px]" />
                        <div className="w-[218px] min-w-[218px] h-[260px] relative ">
                            <img src={imageCenterSrc} alt="" className="w-[218px] h-[260px]" />
                            <img src={imageBorder} alt="" className="w-[167px] h-[165px] absolute top-[40px] left-[26px]" />
                        </div>

                        <img src={imageRightSrc} alt="" className="w-[218px] h-[260px]" />
                    </div>

                    <div className="font-bellota mt-[29px] text-center text-[42px] font-[700] text-[#140D32] leading-[90%] tracking-[-2.52px]">
                        See how you
                        <br />
                        rate with AI
                    </div>
                    <div className="mt-[9px] font-bricolage text-center font-[400] text-[16px] text-[#140D32]">
                        Upload Your Front-Facing Selfie
                    </div>

                    <button onClick={onClickLoadingElem} className="mt-[22px] h-[48px] self-center w-[188px] flex items-center justify-center bg-[#586BFF] rounded-[48px] text-[14px] font-bricolage text-[#fff] font-[500]">
                        Upload a Selfie
                    </button>
                </div>

                <div className="bg-[#fff] flex flex-col pt-[60px] pb-[50px]">
                    <div className="font-bellota text-center text-[42px] font-[700] text-[#140D32] leading-[90%] tracking-[-2.52px]">
                        Get your private
                        <br />
                        score from AI
                    </div>
                    <div className="mt-[12px] font-bricolage text-center font-[400] text-[16px] text-[#140D32]">
                        Share it only you want
                    </div>
                    <img src={resultScoreSrc} alt="" className="mt-[25px] w-[250px] h-[130px] self-center" />

                    <div className="mt-[20px] font-bricolage text-[22px] font-[400] text-[#140D32] tracking-[-0.44px] text-center">
                        You are <span className="text-[#D438C9] font-[700]">Attractive</span>
                    </div>
                </div>

                <div className="bg-[#F1F6FF] py-[34px] w-full flex flex-col items-center">
                    <div className="font-bellota text-center text-[42px] font-[700] text-[#140D32] leading-[90%] tracking-[-2.52px]">
                        Get a full report
                    </div>

                    <div className="mt-[16px] font-bricolage text-center font-[400] text-[16px] leading-[115%] text-[#140D32]">
                        Get a personalized facial
                        <br />
                        aesthetic analysis
                    </div>

                    <div className="w-full mt-[23px] flex justify-center">
                        <ScoreBlock />
                    </div>

                    <button onClick={onClickLoadingElem} className="mt-[38px] h-[48px] self-center w-[188px] flex items-center justify-center bg-[#586BFF] rounded-[48px] text-[16px] font-bricolage text-[#fff] font-[500]">
                        Get started
                    </button>
                </div>

                <div className="bg-[#FFF] pt-[35px] pb-[52px] flex flex-col items-center">
                    <div className="font-bellota text-center text-[42px] font-[700] text-[#140D32] leading-[90%] tracking-[-2.52px]">
                        Become the best
                        <br />
                        version of yourself
                    </div>

                    <img src={personSrc} alt="" className="rounded-[36px] w-[242px] h-[278px] mt-[28px]" />
                    <Bullet bgColor={"#586BFF"} offset={0} text={1} />

                    <div className="font-bricolage mt-[10px] text-[22px] font-[400] text-center text-[#140D32]">
                        Submit Your Photo
                    </div>

                    <Bullet bgColor={"#4000FF"} offset={11} text={2} />

                    <div className="font-bricolage mt-[5px] text-[22px] font-[400] text-center text-[#140D32]">
                        Get an AI score for free
                    </div>

                    <Bullet bgColor={"#7421EC"} offset={7} text={3} />

                    <div className="font-bricolage mt-[10px] text-[22px] font-[400] text-center text-[#140D32]">
                        Request a full report
                    </div>

                    <Bullet bgColor={"#DD46A5"} offset={9} text={4} />

                    <div className="font-bricolage mt-[7px] text-[22px] font-[400] text-center text-[#140D32]">
                        Receive Your
                        <br />
                        Personalized Analysis
                    </div>

                    <div className="mt-[11px] text-[16px] text-[rgba(20,13,50,0.60)] text-center">
                        Within 3-5 business days
                    </div>
                </div>

                <div className="bg-[#F1F6FF] py-[28px] pb-[38px] w-full flex flex-col items-center">
                    <div className="font-bellota text-center text-[42px] font-[700] text-[#140D32] leading-[90%] tracking-[-2.52px]">
                        Uncover your
                        <br />
                        hidden potential
                    </div>

                    <div className="mt-[9px] font-bricolage text-center font-[400] text-[16px] leading-[115%] text-[#140D32]">
                        Get an expert’s recommendations
                    </div>


                    <div className="w-[336px] self-center mt-[25px] flex flex-col rounded-[24px] bg-[#FFF]">
                        <div className="h-[91px] flex">
                            <div className="h-full w-[72px] flex justify-center items-center">
                                <BlockIcon1 />
                            </div>

                            <div className="w-[264px] h-full border-b-[#EEE] border-b-[1px] border-b-solid flex items-center justify-between pr-[23px]">
                                <div>
                                    <div className="font-bricolage text-[22px] text-[#140D32] leading-[115%] tracking-[-0.44px]">
                                        Perfect shape
                                    </div>
                                    <div className="text-[rgba(20,13,50,0.60)] mt-[1px] leading-[115%] tracking-[-0.32px] font-bricolage text-[16px]">
                                        haircut choice is unlimited
                                    </div>
                                </div>

                                <div className="flex items-center justify-center">
                                    <CheckIcon />
                                </div>
                            </div>
                        </div>


                        <div className="h-[91px] flex">
                            <div className="h-full w-[72px] flex justify-center items-center">
                                <BlockIcon2 />
                            </div>

                            <div className="w-[264px] h-full border-b-[#EEE] border-b-[1px] border-b-solid flex items-center justify-between pr-[23px]">
                                <div>
                                    <div className="font-bricolage text-[22px] text-[#140D32] leading-[115%] tracking-[-0.44px]">
                                        Facial proportions
                                    </div>
                                    <div className="text-[rgba(20,13,50,0.60)] mt-[1px] leading-[115%] tracking-[-0.32px] font-bricolage text-[16px]">
                                        almost a golden ratio
                                    </div>
                                </div>

                                <div className="flex items-center justify-center">
                                    <CheckIcon />
                                </div>
                            </div>
                        </div>

                        <div className="h-[91px] flex">
                            <div className="h-full w-[72px] flex justify-center items-center">
                                <BlockIcon3 />
                            </div>

                            <div className="w-[264px] h-full border-b-[#EEE] border-b-[1px] border-b-solid flex items-center justify-between pr-[23px]">
                                <div>
                                    <div className="font-bricolage text-[22px] text-[#140D32] leading-[115%] tracking-[-0.44px]">
                                        Accessory variety
                                    </div>
                                    <div className="text-[rgba(20,13,50,0.60)] mt-[1px] leading-[115%] tracking-[-0.32px] font-bricolage text-[16px]">
                                        match with your features
                                    </div>
                                </div>

                                <div className="flex items-center justify-center">
                                    <CheckIcon />
                                </div>
                            </div>
                        </div>

                        <div className="h-[91px] flex">
                            <div className="h-full w-[72px] flex justify-center items-center">
                                <BlockIcon4 />
                            </div>

                            <div className="w-[264px] h-full border-b-[#EEE] border-b-[1px] border-b-solid flex items-center justify-between pr-[23px]">
                                <div>
                                    <div className="font-bricolage text-[22px] text-[#140D32] leading-[115%] tracking-[-0.44px]">
                                        Skin color
                                    </div>
                                    <div className="text-[rgba(20,13,50,0.60)] mt-[1px] leading-[115%] tracking-[-0.32px] font-bricolage text-[16px] max-w-[180px]">
                                        be careful with red-coloured clothes
                                    </div>
                                </div>

                                <div className="flex items-center justify-center">
                                    <CrossIcon />
                                </div>
                            </div>
                        </div>


                    </div>

                    <button onClick={onClickLoadingElem} className="mt-[22px] h-[48px] self-center w-[188px] flex items-center justify-center bg-[#586BFF] rounded-[48px] text-[14px] font-bricolage text-[#fff] font-[500]">
                        Upload a Selfie
                    </button>

                </div>

                <footer className="w-full flex flex-col items-center py-[37px]">
                    <div className="w-[186px] flex justify-between items-center">
                        <img src={facebookSrc} className="w-[24px] h-[24px]" alt="" />
                        <img src={youtubeSrc} className="w-[31px] h-[21px]" alt="" />
                        <img src={xSrc} className="w-[24px] h-[22px]" alt="" />
                        <img src={lndinSrc} className="w-[23px] h-[24px]" alt="" />
                    </div>
                    <div className="mt-[25px] font-bricolage text-[14px] text-[#BBB]">
                        2024 © All rights reserved
                    </div>

                    <div className="mt-[54px] font-bricolage text-[14px] text-[#BBB] gap-[24px] flex">
                        <Link className="underline" to="/pri" > Privacy Policy</Link >
                        <Link className="underline" to="/ter">Terms of Use</Link>
                    </div>
                </footer>
            </div>
        </div>
    )
}