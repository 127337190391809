import { useEffect } from "react";
import { Loader } from "../components/Loader";
import { getPaymentElemId } from "../utils";
import { makeRequest } from "../utils/request";
import { useHistory } from "react-router-dom";

export const ResultCallbackPage = () => {

    const history = useHistory();

    useEffect(() => {

        const params = new URL(window.location.href)
            .searchParams;
        const checkoudId = params.get("checkout_id");
        getPaymentElemId(checkoudId).then(async ({ resId, email }) => {

            if (email) {

                makeRequest('/user/update', 'post', {
                    tap: 'unlock',
                });

                await makeRequest('/user/update', 'post', {
                    email,
                });
            }

            if (resId) {
                makeRequest('/score/advanced/create', 'post', {
                    score_id: resId,
                }).then(() => {
                    history.push(`/${resId}`)
                });
            }
        }).catch(() => {
            history.push('/err');
        });
        // get params from url
        // make a request to get status and id
        // if everything is correct - make request and go to result page
    }, [history])

    return (
        <>
            <Loader />
        </>
    );
}