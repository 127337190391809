import scoreBaseSrc from '../../images/new-main-page/score-base.png';
import score1 from '../../images/new-main-page/score-1.png';
import score2 from '../../images/new-main-page/score-2.png';
import score3 from '../../images/new-main-page/score-3.png';
import score4 from '../../images/new-main-page/score-4.png';
import { renderWithLineBreaks } from '../../utils';

export const ScoreBlock = () => {
    return (
        <div className='w-[242px] flex flex-wrap gap-[12px] '>
            <ScoreElem scoreImageSrc={score1} score={90} text={'Masculinity \nScore'} />
            <ScoreElem scoreImageSrc={score2} score={78} text={'Skin \nQuality'} />
            <ScoreElem scoreImageSrc={score3} score={74} text={'Facial \nProportions'} />
            <ScoreElem scoreImageSrc={score4} score={85} text={'Authenticity \nScore'} />
        </div>
    )
}

const ScoreImage = ({ scoreImageSrc, score }) => {
    return (
        <div className="w-[70px] h-[70px] relative flex items-center justify-center">
            <img className="w-[70px] h-[70px] absolute top-0 left-0" src={scoreBaseSrc} alt="" />
            <img className="w-[70px] h-[70px] absolute top-0 left-0 z-10" src={scoreImageSrc} alt="" />
            <div className='text-[22px] font-bricolage text-[#000] font-[500]'>
                {score}
            </div>
        </div>
    )
}

const ScoreElem = ({ scoreImageSrc, score, text }) => {
    return (
        <div className='h-[148px] w-[115px] flex flex-col items-center pt-[12px] bg-[#fff] rounded-[16px]'>
            <ScoreImage scoreImageSrc={scoreImageSrc} score={score} />
            <div className='mt-[11px] text-[16px] font-bricolage font-[400] text-center leading-[115%]'>
                {renderWithLineBreaks(text)}
            </div>
        </div>
    )
}