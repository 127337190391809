import { useCallback, useState } from "react";
import { BackIcon } from "../icons"
import { Link, useHistory, useParams } from "react-router-dom";
import { makeRequest } from "../utils/request";
import { Dots } from "../components/Carousel/Dots";
import SimpleCarousel from "../components/Carousel";
import item1Src from '../images/analysis-carousel-1.webp';
import item2Src from '../images/analysis-carousel-2.webp';
import item3Src from '../images/analysis-carousel-3.webp';

export const TipsPaywallPage = () => {

    let { photoId } = useParams();

    const history = useHistory();
    const [currentIndex, setCurrentIndex] = useState(0);
    const goBack = useCallback(() => {
        history.push(`/${photoId}`);
    }, [history, photoId])

    const pay = useCallback(() => {
        window.dataLayer.push({ 'event': 'unlock' });
        makeRequest('/user/update', 'post', {
            tap: 'unlock'
        });
        alert(`This method isn't implemented yet`);
        goBack();
    }, [goBack])

    return (
        <div className="w-full bg-[#f6f7f8] flex justify-center pb-[59px]">
            <div className="w-full max-w-[336px] pt-[20px] flex flex-col items-center">
                <button onClick={goBack} className="bg-[#b6b9c2] ml-[25px] rounded-[50%] self-start flex justify-center items-center w-[38px] h-[38px]">
                    <BackIcon />
                </button>

                <div className="mt-[7px] text-[26px] text-[#232948] font-bold text-center leading-[normal]">
                    LEVEL UP
                </div>

                <div className="text-[18px] text-[#242a46] text-center mt-[12px] leading-[normal]">
                    Proven to help you
                    <br />
                    max your looks
                </div>


                <div className='relative z-3 rounded-[48px] bg-[rgb(36,42,70)] h-[464px] mt-[41px] max-w-[335px] w-[335px] flex flex-col justify-center items-center'>
                    <SimpleCarousel containerClassName="h-[305px] mb-[20px]" onChangeCurrentIndex={setCurrentIndex} >
                        <div className={` leading-[1.2] text-center font-bold text-[32px] text-white font-['Montserrat'] select-none`}>
                            Improvement
                            <br />
                            Coach
                            <img className="ml-[5px] w-[220px] h-[182px] mt-[36px]" alt="" src={item1Src} />
                        </div>

                        <div className={` leading-[1.2] text-center font-bold text-[32px] text-white font-['Montserrat'] select-none`}>
                            Learn about
                            <br />
                            Yourself
                            <img className="w-[220px] h-[196px] mt-[27px]" alt="" src={item2Src} />
                        </div>

                        <div className={` leading-[1.2] text-center font-bold text-[32px] text-white font-['Montserrat'] select-none`}>
                            Start
                            <br />
                            Improving
                            <img className="w-[220px] h-[202px] mt-[26px]" alt="" src={item3Src} />
                        </div>
                    </SimpleCarousel>

                    <div className='absolute z-2 rounded-t-[32px] w-[315px] left-[10px] top-[-12px] h-[60px] bg-[rgba(36,42,70,0.5)]'></div>
                    <div className='absolute z-1 rounded-t-[32px] w-[300px] left-[18px] top-[-24px] h-[60px] bg-[rgba(36,42,70,0.3)]'></div>
                    <div className='absolute z-4 bottom-[20px] w-full flex justify-center'>
                        <div className='w-[36px] flex justify-between items-center '>
                            <Dots amount={3} selectedIndex={currentIndex} />
                        </div>
                    </div>
                </div>

                <button onClick={pay} className='font-bold mt-[20px] w-[320px] h-[60px] flex text-[18px] text-[#fdfcff] justify-center items-center bg-[#0175ff] rounded-[30px]'>
                    Unlock 🔓
                </button>

                <div className="text-[14px] mt-[13px] leading-[1.29] color-[#010101] opacity-80 text-center">
                    Diamond renews for $3/week
                    <br />
                    <Link className="underline" to="/ter">Terms of Use</Link> and <Link className="underline" to="/pri">Privacy Policy</Link>
                </div>
            </div>
        </div>
    )
}
