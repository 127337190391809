import { getColorByType, getTypeByScore, renderWithLineBreaks } from "../../utils";
import { ChartLoadingIcon, ReloadWhiteIcon } from "../../icons";
import { SpeedometerCombined } from "../Speedometer";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useShowExpressScore } from "../../context";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import starSrc from '../../images/iconed-png/star.png';
import crownSrc from '../../images/iconed-png/crown.png';
import starsSrc from '../../images/iconed-png/stars.png';
import chainSrc from '../../images/iconed-png/chain.png';

export const Paywall = ({
    onClickContinue,
}) => {

    const { t } = useTranslation();
    const pay = useCallback(() => {
        window.dataLayer.push({ 'event': 'unlock' });
        onClickContinue('unlock');
    }, [onClickContinue])

    return (
        <>
            <div className="text-[26px] font-[600] text-[#232948] leading-[normal] text-center">
                {renderWithLineBreaks(t('PAYWALL_TITLE'))}
            </div>
            <div className=' mt-[21px]  w-[241px] flex flex-col gap-[8px]'>
                <div className="flex w-full items-center gap-[8px] h-[61px] px-[8px] bg-[#811883] rounded-[16px]">
                    <div className="w-[32px] min-w-[32px] flex items-center justify-center">
                        <img className="w-[auto] h-[24px]" src={starSrc} alt="" />
                    </div>
                    <div className="text-[#f2f2f2] text-[14px] leading-[1.29] font-[500]">
                        {renderWithLineBreaks(t('PAYWALL_VALUE_RATINGS'))}
                    </div>
                </div>
                <div className="flex w-full items-center gap-[8px] h-[61px] px-[8px] bg-[#811883] rounded-[16px]">
                    <div className="w-[32px] min-w-[32px] flex items-center justify-center">
                        <img className="w-[auto] h-[22px]" src={crownSrc} alt="" />
                    </div>

                    <div className="text-[#f2f2f2] text-[14px] leading-[1.29] font-[500]">
                        {renderWithLineBreaks(t('PAYWALL_VALUE_FEATURES'))}
                    </div>
                </div>
                <div className="flex w-full items-center gap-[8px] h-[61px] px-[8px] bg-[#811883] rounded-[16px]">
                    <div className="w-[32px] min-w-[32px] flex items-center justify-center">
                        <img className="w-[auto] h-[22px]" src={starsSrc} alt="" />
                    </div>

                    <div className="text-[#f2f2f2] text-[14px] leading-[1.29] font-[500]">
                        {renderWithLineBreaks(t('PAYWALL_VALUE_SELFIES'))}
                    </div>
                </div>

                <div className="flex w-full items-center gap-[8px] h-[61px] px-[8px] bg-[#811883] rounded-[16px]">
                    <div className="w-[32px] min-w-[32px] flex items-center justify-center">
                        <img className="w-[auto] h-[24px]" src={chainSrc} alt="" />
                    </div>

                    <div className="text-[#f2f2f2] text-[14px] leading-[1.29] font-[500]">
                        {renderWithLineBreaks(t('PAYWALL_VALUE_URL'))}
                    </div>
                </div>
            </div>
            <div className="mt-[10px] flex">
                <button onClick={pay} className='font-bold w-[240px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[16px]'>
                    {renderWithLineBreaks(t('PAYWALL_BUTTON'))}
                </button>
            </div>
            <div className="mt-[13px] text-center text-[14px] opacity-80 text-[#010101] leading-[1.29]">

                {renderWithLineBreaks(t('PAYWALL_NOTICE'))}
            </div>
        </>
    )
}