import { isAndroid, isTelegram, renderWithLineBreaks } from "../../utils";
import { useTranslation } from "react-i18next";
import { IconGallery } from "../../icons";
import usePhotoLoader from "../../hooks/usePhotoLoader";
import { useCallback } from "react";

export const PhotoLoading = ({
    onPhotoLoaded,
    text,
    exampleSrc
}) => {
    const { openFileSelector } = usePhotoLoader(onPhotoLoaded)
    const { t } = useTranslation();

    const openWebcam = useCallback(() => {
        window.dataLayer.push({ 'event': 'take_a_selfie' });
        openFileSelector('webcam')
    }, [openFileSelector])

    const openGalleryForPhotos = useCallback(() => {
        window.dataLayer.push({ 'event': 'upload_photo' });
        openFileSelector('none')
    }, [openFileSelector])

    return (
        <>
            <div className="text-[25px] font-[600] text-[#232948] leading-[normal] text-center">
                {renderWithLineBreaks(text || '')}
            </div>
            <img src={exampleSrc} alt="" className='mt-[20px] h-[240px] rounded-[32px] w-[240px]' />
            <div className="mt-[20px] flex">
                {(isAndroid() && isTelegram()) ?
                    <button onClick={openGalleryForPhotos} className='flex  font-bold  w-[240px] h-[60px] text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[30px]'>
                        {renderWithLineBreaks(t('UPLOAD_SELFIE_2_STEP_1_BUTTON_IMG'))}
                    </button>
                    :
                    <>
                        <button onClick={openGalleryForPhotos} className='md:hidden font-bold  w-[64px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[50%]'>
                            <IconGallery />
                        </button>

                        <button onClick={openWebcam} className='md:hidden font-bold ml-[10px]  w-[161px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[30px]'>
                            {renderWithLineBreaks(t('UPLOAD_SELFIE_2_STEP_2_BUTTON_CAMERA'))}
                        </button>

                        <button onClick={openGalleryForPhotos} className='md:flex hidden font-bold  w-[240px] h-[60px] text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[30px]'>
                            {renderWithLineBreaks(t('UPLOAD_SELFIE_2_STEP_1_BUTTON_IMG'))}
                        </button>
                    </>
                }
            </div>
        </>
    )
}