import { getColorByType, getTypeByScore, loadCharacter, renderWithLineBreaks } from "../../utils";
import { DownloadIcon, ReloadWhiteIcon } from "../../icons";
import { SpeedometerCombined } from "../Speedometer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import html2canvas from "html2canvas-pro";
import { useTranslation } from "react-i18next";
import ReactSimplyCarousel from 'react-simply-carousel';
import CarouselWithCenteredSlide from "../SwiperCarousel";
import fireSrc from '../../images/iconed-png/fire.png';
import hmmSrc from '../../images/iconed-png/hmm.png';
import lampSrc from '../../images/iconed-png/lamp.png';
import { useShowError, useShowPotential, useTimerDelay } from "../../context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Lottie from "react-lottie";
import * as animationData from '../../animations/loading-character.json';
import { Dots } from "../Carousel/Dots";

const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    className: "self-center",
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

let settings = {
    showPotential: false
}

export const ScorePreview = ({
    onClickGetScore,
    score,
    status,
    image,
    texts = [],
    scoreId
}) => {
    const colorTypeOfScore = getTypeByScore(score);
    const colorOfScore = getColorByType(colorTypeOfScore);
    const renderedResult = useRef();
    const showPotential = useShowPotential();
    const timerDelay = useTimerDelay();
    const [characterUrl, setCharacterUrl] = useState('');
    const [isCharacterLoading, setIsCharacterLoading] = useState(showPotential);
    const showError = useShowError();
    const history = useHistory();
    const [characterProgress, setCharacterProgress] = useState('')

    settings.showPotential = showPotential;

    const { t } = useTranslation();

    useEffect(() => {
        loadCharacter(scoreId, setCharacterProgress, () => { }, timerDelay).then((result,) => {
            if (!result) {
                return;
            }
            if (result?.data?.status) {
                setIsCharacterLoading(false);
                setCharacterUrl(result.data.result.url);
            } else {
                showError(result.data.message || 'No Internet Connection');
                console.error('error', result);
                history.push('/err');
            }
        });
    }, [history, scoreId, showError, timerDelay])

    const getImage = useCallback(() => {
        if (renderedResult.current) {
            html2canvas(renderedResult.current).then((canvas) => {
                let img = canvas.toDataURL("image/jpeg");
                // window.open(img);

                const linkSource = img;
                const downloadLink = document.createElement('a');
                document.body.appendChild(downloadLink);

                downloadLink.href = linkSource;
                downloadLink.target = '_self';
                downloadLink.download = 'saved';
                downloadLink.click();
            }).catch((e) => {
                console.error(e);
            })
        }
    }, [])

    const responsive = {
        any: {
            breakpoint: { max: 3000, min: 0 },
            items: 1,
            partialVisibilityGutter: 24 // this is needed to tell the amount of px that should be visible.
        }
    }

    const slideOne = useMemo(() => (
        <>
            <div className="text-[26px] font-[600] text-[#232948] leading-[normal] text-center">
                {renderWithLineBreaks(t('EXPRESS_SUMMARY_TITLE'))}
            </div>

            <div className="h-[241px] w-[241px] flex flex-col gap-[8px] mt-[21px]">
                <div className="flex w-full items-center gap-[8px] h-[75px] px-[8px] bg-[#242a46] rounded-[16px]">
                    <div className="min-w-[32px] flex items-center justify-center">
                        <img className="w-[auto] h-[22px]" src={lampSrc} alt="" />
                    </div>
                    <div className="text-[#f2f2f2] text-[14px] leading-[1.29] font-[500]">
                        {renderWithLineBreaks(texts[0])}
                    </div>
                </div>

                <div className="flex w-full items-center gap-[8px] h-[75px] px-[8px] bg-[#242a46] rounded-[16px]">
                    <div className="min-w-[32px] flex items-center justify-center">
                        <img className="w-[auto] h-[24px]" src={fireSrc} alt="" />
                    </div>

                    <div className="text-[#f2f2f2] text-[14px] leading-[1.29] font-[500]">
                        {renderWithLineBreaks(texts[1])}
                    </div>
                </div>
                <div className="flex w-full items-center gap-[8px] h-[75px] px-[8px] bg-[#242a46] rounded-[16px]">
                    <div className="min-w-[32px] flex items-center justify-center">
                        <img className="w-[auto] h-[22px]" src={hmmSrc} alt="" />
                    </div>

                    <div className="text-[#f2f2f2] text-[14px] leading-[1.29] font-[500]">
                        {renderWithLineBreaks(texts[2])}
                    </div>
                </div>
            </div>
        </>
    ), [texts, t])

    const slideTwo = useMemo(() => (
        <>
            <div className="text-[26px] font-[600] text-[#232948] leading-[normal] text-center">
                {renderWithLineBreaks(t('EXPRESS_RATING_TITLE'))}
            </div>

            <div className='rounded-[16px]  mt-[21px] bg-[#242a46] h-[241px] min-w-[241px] pt-[41px] flex flex-col  items-center relative'>
                <SpeedometerCombined value={score} />

                <div className="flex justify-center mt-[19px]">
                    {status && (<div className=" font-['Montserrat'] text-[28px] font-bold h-[28px] rounded flex justify-center items-center px-3 text-white">
                        {status}
                    </div>)}
                </div>
            </div>
        </>
    ), [status, score, t])

    const slideThree = useMemo(() => (
        <>
            <div className="text-[26px] font-[600] text-[#232948] leading-[normal] text-center">
                {renderWithLineBreaks(t('EXPRESS_IMAGE_TITLE'))}
            </div>

            <div className='rounded-[16px] flex justify-center mt-[21px] h-[241px] w-[241px] bg-[rgba(36,42,70,0.1)]'>

                {
                    (isCharacterLoading && !characterUrl) && <div className="self-center w-[160px] relative left-[-2px] top-[14px]" >
                        <Lottie options={defaultLottieOptions}
                            height={90}
                            width={160}
                        />
                        <div className="h-[24px] text-[18px] mt-[4px] font-header font-bold color-[#000000] text-center">{characterProgress}</div>
                    </div>
                }
                <>
                    {characterUrl && <img alt="" src={characterUrl} className=" rounded-[16px] h-[241px] w-[241px]"></img>}
                </>
            </div>
        </>
    ), [isCharacterLoading, characterUrl, characterProgress, t])

    const slides = useMemo(() => {
        const res = [slideOne]
        if (score && status) {
            res.push(slideTwo);
        }
        if (showPotential) {
            res.push(slideThree);
        }
        return res;
    }, [showPotential, score, status, slideOne, slideTwo, slideThree])

    return (
        <>
            {
                slides.length > 1 ? <CarouselWithCenteredSlide slides={slides} />
                    : <>
                        {slideOne}
                        <div className='w-[8px] mt-[16px] flex justify-between items-center '>
                            <Dots amount={1} selectedIndex={0} />
                        </div>
                    </>
            }

            <div className="mt-[17px] flex">
                <button onClick={onClickGetScore} className='font-bold w-[240px] h-[64px] flex text-[20px] text-[white] justify-center items-center bg-[#0076ff] rounded-[16px]'>
                    {renderWithLineBreaks(t('EXPRESS_BUTTON'))}
                </button>
            </div>

            <div className="mt-[14px] text-center text-[14px] opacity-80 text-[#010101] leading-[1.29]">
                {renderWithLineBreaks(t('EXPRESS_NOTICE'))}
            </div>

            <div ref={renderedResult} className="absolute left-[-1500px] w-[335px] h-[430px] bg-[#242a46] flex flex-col pt-[55px]">
                <img
                    alt=""
                    src={image}
                    className="self-center bg-cover border border-solid font-[15px] border-[#6c7183] w-[150px] h-[150px] rounded-[50%] bg-no-repeat bg-center"
                />
                <div className="flex justify-center mt-[30px]">
                    <SpeedometerCombined value={score || 50} />
                </div>
                <div className="flex justify-center mt-[21px]">
                    {status && (<div style={{ backgroundColor: colorOfScore.bg, color: colorOfScore.text }} className="text-[15px] h-[28px] rounded flex justify-center items-center px-3 text-white">
                        {status}
                    </div>)}
                </div>
            </div>
        </>
    )
}