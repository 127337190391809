import { useCallback, useState } from 'react';

const usePhotoLoader = (photoLoadCallback) => {
  const [photoBlob, setPhotoBlob] = useState(null);
  const loadPhoto = useCallback(async (event) => {
    const input = event.target;
    const imageFiles = Array.from(event.target.files);
    const imageFile = imageFiles[0]

    setTimeout(async () => {
      setPhotoBlob(imageFile);
      if (photoLoadCallback) {
        const base = await getBase64(imageFile);
        photoLoadCallback?.(imageFile, base);
      }
      input.value = "";
    }, 200);
    input.removeEventListener('change', loadPhoto);
  }, [photoLoadCallback])

  const openFileSelector = useCallback((type) => {
    const input = document.querySelector('#file-loader-input');
    if (type === 'webcam') {
      input.capture = "user"
    } else {
      input.removeAttribute('capture');
    }

    input.addEventListener('change', loadPhoto);
    setTimeout(() => {
      input.click();
    }, 200)

  }, [loadPhoto])
  return { openFileSelector, photoBlob };
};

export default usePhotoLoader;


function getBase64(file) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result)
    };
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  })

}