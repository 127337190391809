

import { useEffect } from 'react';
import '../styles/Legal.css'
import { isTelegram } from '../utils';


export const PrivacyPage = () => {

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', isTelegram() ? '#FFFFFF' : '#f6f7f8');
    }, [])

    return (
        <div className='flex flex-col  bg-white py-[38px] px-[20px]'>
            <h1>
                Privacy Policy
            </h1>
            <h3>
                Privacy Policy Effective Date: April 01, 2023
            </h3>

            <h3>
                This Privacy Policy (“Privacy Policy”) describes the data privacy practices of FaceQ (collectively, FaceQ,” “we,” “us,” or “our”), with respect to the services including our website at www.FaceQ.link and our mobile application (collectively, the “Service”). This Privacy Policy will explain the types of personal information that we collect, the uses we make of that information, and how you can correct or request deletion of any personal identifying information you choose to give us.
            </h3>
            <h3>
                We collect and use personal information from and about you
            </h3>
            <p>
                FaceQ (and the vendors we hire to perform business functions on our behalf) may collect, receive, and develop several categories of personal information:
                <ul>
                    <li>We collect Social Network Information: We may collect your social network username details.</li>
                    <li>We collect Registration and Account Information: We may collect display name, reminder questions and answers, and communications preferences. You may also provide other optional information, such as a profile avatar, and general location.</li>
                    <li>We collect Internet and Network Activity Information: We collect information about your use of the Site, such as frequency of use, engagement with particular features, and which sections of the Site are visited most. We may also collect information about the browser you are using, your IP address, and device identifiers.</li>
                    <li>We collect Location Information: We collect information about your general location, using your IP address.</li>
                    <li>We collect information when you communicate with us: We may send push notifications to your mobile device if they are enabled to verify your account and for informational and operational purposes, such as account management, customer service, system maintenance, and other Service-related information.</li>
                    <li>We collect Other Personal Information Consumers Voluntarily Choose to Provide: We collect information such as your posts and other submissions to our Service, including our mobile application which is intended to facilitate the creation of publicly available information and content.</li>
                </ul>


            </p>

            <h3>We use personal information as described here</h3>

            <h3>We use these categories of personal information for the following purposes:</h3>
            <ul>
                <li>We use information to respond to your requests or questions. We use personal information to provide you with customer support anytime you reach out to us with questions about our Privacy Policy or Terms of Use, or issues arising from your account. We also use personal information to send customers technical alerts, updates, security notifications, and administrative communications.</li>
                <li>We use information in connection with your purchases. We use Contact Information and Payment Information to process transactions and fulfill your orders.</li>
                <li>We use information to improve our products and services. For example, we may use the information you provide about our products, as well as Payment Information, Internet and Network Activity, Location, and Inferences to make our website, services, and products better.</li>
                <li>We use information to administer our site and services and for internal operations. We use all categories of personal information for our internal operations, such as troubleshooting, insurance claims, data analysis, and testing.</li>
                <li>We use information for security purposes. We may use each category of personal information as necessary to protect our company, our customers, and our business partners. We also may use this personal information to protect our websites or our services, as well as to detect and investigate activities that may be illegal or prohibited (such as cyber attacks or fraudulent activities).</li>
                <li>We use information to verify requests made pursuant to this Privacy Policy. We will use certain pieces of personal information to verify your identity if you make requests pursuant to this Privacy Policy. The verification steps and the pieces of personal information that we request may vary depending on the sensitivity and nature of your request.</li>
                <li>We use information as other disclosed or permitted by law, or as we may notify you.</li>
                <li>We collect personal information about you from various sources. We collect personal information about you from various sources, directly and indirectly. We collect these categories of personal information from the following categories of sources:</li>
                <ul>
                    <li>We collect personal information directly from you. We collect Contact Information if you sign up for our newsletter or contact us. We collect Payment Information if you make a purchase. We also collect any personal information you provide if you make a request or complete a survey.</li>
                    <li>We collect personal information from your devices. We use tracking tools like browser cookies and web beacons to automatically collect information about you (including Internet and Network Activity, and Location). We collect this information when you interact with our websites, our ads, and our emails that we or our vendors send to you. We may have third parties collect personal information on our behalf in this way, too.</li>
                    <li>We get personal information from vendors we hire to work on our behalf. Vendors that host or maintain our websites and send promotional emails for us may give us Contact Information and Internet and Network Activity Information. Our marketing agencies, advertising technology vendors, and analytics providers may provide us with Contact Information, Payment Information, Internet and Network Activity Information, and Location Information.</li>
                </ul>
            </ul>
            <h3>We share certain personal information</h3>

            <h3> ‍We share these categories of personal information under the following circumstances:</h3>
            <ul>
                <li>We share your personal information with vendors who perform services on our behalf. We may share each category of personal information with vendors who send emails for us, help us manage and operate our sites, fulfill purchases, provide advertising or marketing services, provide analytics and search engine support, track advertising impressions, investigate and prevent data incidents, audit our business and financial statements, provide legal advice, and place our advertisements on other platforms.</li>
                <li>You may select to share or make personal information available to social media platforms and third parties you interact with on the Service. The Service may contain plug-ins from third party platforms that allow you to like and share content from the Service. The Services may also allow you to sign-into your account using your login and password from other platforms. When you interact with these platforms on our Service, those third parties will collect personal information from you.</li>
                <li>We may share your personal information with our business partners and marketing companies. We might share Contact Information, Inferences, and Location Information with our distributors and dealers. We may also share the above categories of personal information with companies that help us with our marketing efforts, including social media platforms, advertising networks, and ad tech companies.</li>
                <li>We may share your information if we think we have to in order to comply with the law or to protect ourselves, our customers, and others. We may share any or all categories of personal information to respond to a court order or subpoena. We may also share this personal information if a government agency or investigatory body requests it. This includes sharing your information with the US government. We might share your personal information in order to enforce our agreements and to protect the rights of others. We might share your personal information when we are investigating potential fraud.</li>
                <li>We may share your personal information with a successor to all or part of our business. If part of our business is sold, we may include user information as part of that transaction, which may include some or all categories of your personal information. We may also share your personal information if there is a similar change to our corporate structure. We may also share your information with others as they conduct diligence of our corporate changes.</li>
                <li>We may share personal information for other reasons we may describe to you from time to time as permitted by law.</li>
            </ul>
            <h3>You have certain choices about how we use your personal information. You can control certain cookies and tracking tools.</h3>
            <h4> ‍We use the following types of cookies:</h4>

            <h3> ‍Strictly necessary cookies</h3>
            These are cookies that are required for the operation of our web site. They include, for example, cookies that enable you to log into secure areas of our web site. This category of cookies cannot be disabled.

            ‍<h3>Analytical cookies</h3>
            These cookies allow us to recognize and count the number of visitors and to see how visitors move around our web site when they are using it. This helps us to improve the way our web site works, for example, by ensuring that users are finding what they are looking for easily. Information collected by Google Analytics cookies will be transmitted to and stored by Google on servers in the United States of America in accordance with its privacy practices. To see and overview of privacy at Google and how this applies to Google Analytics, visit https://www.google.com/intl/en/policies/privacy/. You may opt out of tracking by Google Analytics by visiting https://tools.google.com/dlpage/gaoptout.

            ‍<h3>Advertising or targeting cookies</h3>
            <p>These cookies are used to make advertising more relevant to you and your interests. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed, and in some cases selecting advertisements that are based on your interests. Our third party advertising partners may use the cookies to build a profile of your interests and deliver relevant advertising on other websites. You may disable cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to disable all cookies (including strictly necessary cookies) you may not be able to access all or parts of the site. Disabling a cookie or category of cookie does not delete the cookie from your browser. You will need to do this separately within your browser. If you have disabled one or more analytical cookies, we may still use information collected from cookies prior to your disabled preference being set.</p>
            <h3>Your California Privacy Rights</h3>

            <p>If you reside in California, you have the right to ask us one time each year if we have shared personal information with third parties for their direct marketing purposes. To make a request, please send us an email, or write to us at the address listed below. Indicate in your letter that you are a California resident making a “Shine the Light” inquiry. If you reside in California, you have the right to make the following requests with respect to your personal information:</p>

            <ul>
                <li>‍Access — You have the right to request that we disclose to you the categories of personal information collected about you, the categories of sources from which the personal information is collected, the categories of personal information sold or disclosed, the business or commercial purpose for collecting and selling the personal information, the categories of third parties with whom we share the personal information, and the specific pieces of personal information collected about you over the past 12 months.</li>
                <li>Deletion — You can request that we delete your personal information that we maintain about you, subject to certain exceptions.</li>
                <li>‍Opt-Out — You can request to opt-out of the sale of your personal information to a third party. For purposes of this privacy policy, “sell” means the sale, rental, release, disclosure, dissemination, availability, transfer, or other oral, written, or electronic communication of your personal information to an outside party for monetary or other valuable consideration.</li>
                <li>‍Right to be free from discrimination — You have the right not to be discriminated against for exercising these rights. We may deny certain requests, or fulfill a request only in part, based on our legal rights and obligations. For example, we may retain personal information as permitted by law, such as for tax or other record keeping purposes, to maintain an active account, and to process transactions and facilitate customer requests. We will take reasonable steps to verify your identity prior to responding to your requests. The verification steps will vary depending on the sensitivity of the personal information and whether you have an account with us. You can also designate an authorized agent to make a request on your behalf. If you use an authorized agent, please include written permission that you have designated that agent to make the request, or proof of the agent‘s power of attorney. We may follow up with you to verify your identity before processing your authorized agent’s request. You may exercise the above rights by emailing us at hello@FaceQ.link</li>
            </ul>

            <h3> ‍Our site is not intended for children</h3>

            ‍We do not knowingly collect information from children under 18. We will take steps to delete it if we learn we have collected it. We do not knowingly collect, maintain, or use personal information from children under 18 years of age, and no part of the Service is directed to children under the age of 18. If you learn that your child has provided us with personal information without your consent, you may alert us at hello@FaceQ.link. If we learn that we have collected any personal information from children under 18, we will promptly take steps to delete such information and terminate the child’s account.
            International data transfers

            FaceQ is headquartered in Saint Vincent and the Grenadines and may have service providers in other countries. Your personal information may be transferred to other locations outside of your state, province, country, or other governmental jurisdiction where privacy laws may not be as comprehensive as those in your jurisdiction.
            ‍We may link to third party sites or services we do not control

            ‍If you click on a link to a third party site, you will be taken to a website we do not control. This Policy does not apply to the privacy practices of that website, which may collect information from you. Read the privacy policy of other websites carefully. We are not responsible for these third party websites or their policies.
            <h3>Security</h3>

            We take significant steps to protect your personal identifying information and keep it safe, for reasons we cannot guarantee that all of your personal identifying information will never be disclosed in ways you do not expect or which are not described in this Privacy Policy. Your information and its security is a top priority for our team.

            ‍<h3>Contact us</h3>
            Email: hello@FaceQ.link

            ‍We may update this Policy From time to time we may change our privacy policies. We will notify you of any material changes to our Policy as required by law. We will also post an updated copy on our website. Please check our site periodically for updates. Your continued use of our website means that you consent to the collection, use and disclosure of your personal information as set out in the updated Policy.

        </div>
    )
}