import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SpeedometerCombined } from "../Speedometer";
import { getColorByType, getTypeByScore, imageUrlToBase64, isTelegram, renderWithLineBreaks } from "../../utils";
import { BackIcon, ShareIcon, DownloadIcon, ReloadIcon, DangerIcon, PDFIcon, RemoveIcon, PlusIcon, PlusIconWhite } from "../../icons";
import { useHistory, Link } from "react-router-dom";
import { useBlockSettings, useSetPhoto, useShowBlockReport, useShowButtonTips, useShowPotential, useShowSharing } from "../../context";
import "../../styles/Animation.css";
import * as animationData from '../../animations/loading-character.json';
import Lottie from "react-lottie";
import { SharingBlock } from "./SharingBlock";
import { makeRequest } from "../../utils/request";
import { useTranslation } from "react-i18next";
import ScoreDisplay from "../BlockScore";
import html2canvas from 'html2canvas-pro';
import { TipsModal } from "../ModalTips";
import { FeedbackModal } from "../ModalFeedback";
import { ReferalBlock } from "./ReferalBlock";
import { TipsPreviewModal } from "../ModalTipsPreview";
import CircleProgressBar from "../ProgressBar";
import { InsightsModal } from "../ModalInsights";
import { ResultScoreBlock } from "../BlockScore/ResultScoreBlock";
import { ButtonElem } from "./ButtonElem";
import { FeaturesBlock } from "./Features";
import { ModalFullAnalysis } from "../ModalFullAnalysis";
import { ModalFullProfile } from "../ModalFullProfile";
import { ModalBeautyTips } from "../ModalBeautyTips";
import { PhotoLoadingModal } from "../ModalLoading";


export const Result = ({
    photoSrc,
    onGoAnalysis,
    ratings,
    isAuthorSession,
    score,
    status,
    isCharacterLoading,
    characterUrl,
    characterProgress,
    timeoutFallenFetch,
    photoId,
    isPreview,
    hideCharacter,
    potential,
    features,
    faceShape,
    photoLeft,
    photoRight,

}) => {

    const { t } = useTranslation();
    const { title, showBlock, caption } = useBlockSettings();
    const renderedResult = useRef()
    const [image, setImage] = useState(photoSrc)
    const history = useHistory();
    const setPhoto = useSetPhoto();
    const showSharing = useShowSharing();
    const showReport = useShowBlockReport();
    const showButtonTips = useShowButtonTips();

    const [opened, setOpenModal] = useState(false);
    const [openedInsightsModal, setOpenedInsightsModal] = useState(false);

    const [photoLoadingModalOpened, setPhotoLoadingModalOpened] = useState(false);
    const [photoLoadingModalMode, setPhotoLoadingModalMode] = useState('');

    const closePhotoLoadingModal = useCallback(() => {
        setPhotoLoadingModalOpened(false);
    }, [])

    const openPhotoLoadingModal = useCallback((mode) => {
        setPhotoLoadingModalOpened(true);
        setPhotoLoadingModalMode(mode);
    }, [])

    const openPhotoLoadingModalLeft = useCallback(() => {
        openPhotoLoadingModal('left');
    }, [openPhotoLoadingModal])

    const openPhotoLoadingModalRight = useCallback(() => {
        openPhotoLoadingModal('right');
    }, [openPhotoLoadingModal])

    const closeInsightModal = useCallback(() => {
        setOpenedInsightsModal(false);
    }, [])

    const [FeedbackModalOpened, setFeedbackModalOpened] = useState(false);


    const closeFeedbackModal = useCallback(() => {
        setFeedbackModalOpened(false);
    }, [])

    const closeModal = useCallback(() => {
        setOpenModal(false)
    }, [])


    const [fullAnalysisModalOpened, setFullAnalysisModalOpened] = useState(false);

    const closeFullAnalysisModal = useCallback(() => {
        setFullAnalysisModalOpened(false);
    }, []);

    const [fullProfileModalOpened, setFullProfileModalOpened] = useState(false);


    const closeFullProfileModal = useCallback(() => {
        setFullProfileModalOpened(false);
    }, []);

    const [tipsModalOpened, setTipsModalOpened] = useState(false);

    const openTipsModal = useCallback(() => {
        setTipsModalOpened(true);
    }, []);

    const closeTipsModal = useCallback(() => {
        setTipsModalOpened(false);
    }, []);

    const colorTypeOfScore = getTypeByScore(score);
    const colorOfScore = getColorByType(colorTypeOfScore);


    const defaultLottieOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        className: "self-center",
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        if (photoSrc) {
            imageUrlToBase64(photoSrc).then((val) => {
                setImage(val)
            });
        }
    }, [photoSrc])

    const getImage = useCallback(() => {
        if (renderedResult.current) {
            html2canvas(renderedResult.current).then((canvas) => {

                let img = canvas.toDataURL("image/jpeg");
                // window.open(img);

                const linkSource = img;
                const downloadLink = document.createElement('a');
                document.body.appendChild(downloadLink);

                downloadLink.href = linkSource;
                downloadLink.target = '_self';
                downloadLink.download = 'saved';
                downloadLink.click();
            }).catch((e) => {
                console.error(e);
            })
        }
    }, [])

    const goToMain = useCallback(() => {
        history.push('/')
        setPhoto('')
    }, [history, setPhoto])

    const onShare = useCallback(async () => {
        window.dataLayer.push({ 'event': 'share' });

        makeRequest('/user/update', 'post', {
            tap: 'share'
        });

        if (isTelegram()) {
            const a = document.createElement("a");
            a.href = `https://t.me/share/url?url=${encodeURI('t.me/yasss_app_bot/yasss')}?startapp=${photoId}&text=${'Check Out How the AI Rates Me!'}`;
            a.target = '_blank';
            a.rel = 'noopener noreferrer';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            return;
        }

        if (!navigator.canShare) {
            return;
        }
        const url = window.location.href;
        const shareData = {
            title: "FaceQ: AI Face Score",
            text: "See How You Rate with AI",
            url,
        };
        try {
            await navigator.share(shareData);
        } catch (err) {
            console.error('failed sharing:', err);
        }
    }, [photoId])

    const downloadLinkPromise = useMemo(async () => {
        if (characterUrl) {
            try {
                const blob = await fetch(characterUrl).then(res => res.blob());
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url
                const name = (new URL(characterUrl)).pathname.replace('/tmp/', '');
                a.download = name;
                return a;
            } catch (e) {
                console.error(e);
            }
        }
    }, [characterUrl])

    const onDownloadImage = useCallback(async () => {
        if (downloadLinkPromise) {
            downloadLinkPromise.then((link) => {
                if (link) {
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
        }
        return;
    }, [downloadLinkPromise])

    const onReload = useCallback(() => {
        if (!characterUrl && timeoutFallenFetch) {
            window.location.reload();
        }
    }, [timeoutFallenFetch, characterUrl]);


    const onGetFullAnalysis = useCallback(() => {
        if (isAuthorSession) {
            makeRequest('/user/update', 'post', {
                tap: 'analysis'
            });
        }

        history.push(`/scores/${photoId}/ratings`);

        // openFullAnalysisModal();
    }, [isAuthorSession, photoId, history])

    const onGetFullProfile = useCallback(() => {
        if (isAuthorSession) {
            makeRequest('/user/update', 'post', {
                tap: 'profile'
            });
        }
        history.push(`/scores/${photoId}/features`);
    }, [isAuthorSession, history, photoId])

    const onGetTips = useCallback(() => {
        if (isAuthorSession) {
            makeRequest('/user/update', 'post', {
                tap: 'tips'
            });
        }
        alert(`This method isn't implemented yet`);
        // openTipsModal();
    }, [isAuthorSession, openTipsModal])

    const runCompatibility = useCallback(() => {
        makeRequest('/user/update', 'post', {
            tap: 'compatibility'
        });
        alert(`This method isn't implemented yet`);
    }, [])

    const onClickInsights = useCallback(() => {
        makeRequest('/user/update', 'post', {
            tap: 'report'
        });
        alert(`This method isn't implemented yet`);
    }, [])

    return <div className={`${isTelegram() ? 'bg-[#FFFFFF]' : 'bg-[#f6f7f8]'} relative w-full flex flex-col overflow-hidden items-center`} >
        {isAuthorSession && <div className="flex sm:mt-[42px] mt-[20px] w-[291px] justify-between">
            <button onClick={goToMain} className="bg-[#b6b9c2] rounded-[50%] flex justify-center items-center w-[38px] h-[38px]">
                <a href="/"><BackIcon /></a>
            </button>

            <button onClick={onShare} className={`bg-[#b6b9c2] items-center justify-center rounded-[50%] flex w-[38px] h-[38px]`}>
                <ShareIcon />
            </button>
        </div>}

        {/* <div className="mt-[18px] w-full flex items-center justify-center">
            <img
                alt=""
                src={image}
                className="self-center bg-cover border-[1px] border-solid font-[15px] border-[#B6B9C2] w-[179px] h-[179px] rounded-[50%] bg-no-repeat bg-center"
            />
        </div>

        <div className="mt-[20px] text-center text-[32px] text-[#242a46] font-bold leading-[normal]">
            {renderWithLineBreaks(t('RESULT_TITLE'))}
        </div>

        <div className="mt-[31px] w-full max-w-[290px] flex flex-col self-center bg-[#242a46] h-[290px] rounded-[16px] pt-[65px]">

            <div className="flex justify-center">
                <SpeedometerCombined value={score || 50} />
            </div>

            <div className="flex justify-center mt-[19px]">
                {status && (<div className=" font-['Montserrat'] text-[30px] font-bold h-[28px] rounded flex justify-center items-center px-3 text-white">
                    {status}
                </div>)}
            </div>
        </div> */}

        {/* <ResultScoreBlock score={score} potential={potential} /> */}

        <div className={`relative z-10 h-[430px] flex flex-col pt-[29px] w-full rounded-[50px] bg-[#242a46] mt-[40px] max-w-[335px] ${isAuthorSession ? 'mt-[40px]' : 'mt-[44px]'} `}>
            <div className='absolute z-2 rounded-t-[32px] w-[315px] left-[10px] top-[-12px] h-[40px] bg-[rgba(36,42,70,0.5)]'></div>
            <div className='absolute z-1 rounded-t-[32px] w-[300px] left-[18px] top-[-24px] h-[40px] bg-[rgba(36,42,70,0.3)]'></div>

            <img
                alt=""
                src={image}
                className="self-center bg-cover border-[2px] border-solid font-[15px] border-[#6c7183] w-[182x] h-[182px] rounded-[50%] bg-no-repeat bg-center"
            />

            <div className="flex justify-center mt-[28px]">
                <SpeedometerCombined value={score || 50} />
            </div>

            <div className="flex justify-center mt-[19px]">
                {status && (<div className=" font-['Montserrat'] text-[30px] font-bold h-[28px] rounded flex justify-center items-center px-3 text-white">
                    {status}
                </div>)}
            </div>
        </div>

        {(!isTelegram() && isAuthorSession && showSharing) && <SharingBlock characterUrl={characterUrl} />}

        {/* <div className="max-w-[260px] text-[#242a46] mt-[40px] text-base leading-[normal] font-bold flex justify-center text-center tracking-[normal]">
            Your Photos
        </div> */}

        {isAuthorSession && <>
            <div className="w-[290px] flex flex-wrap gap-[10px] mt-[16px] cursor-pointer">
                <div className="w-[90px] group h-[90px] rounded-[16px] flex justify-center relative">
                    <img alt="" className="w-[90px] h-[90px] rounded-[16px]" src={image} />
                    {/* <div className="h-[30px] w-[30px] hidden absolute top-[8px] right-[8px] rounded-[50%] group-hover:flex justify-center items-center bg-[#000] bg-opacity-30">
                    <RemoveIcon />
                </div> */}
                </div>

                {photoLeft ?
                    <div className="w-[90px] group h-[90px] rounded-[16px] flex justify-center relative">
                        <img alt="" className="w-[90px] h-[90px] rounded-[16px]" src={photoLeft} />
                    </div>
                    :
                    <div className="bg-[#b6b9c2] rounded-[16px] w-[90px] h-[90px] flex justify-center items-center" onClick={openPhotoLoadingModalLeft}>
                        <PlusIconWhite />
                    </div>}

                {photoRight ?
                    <div className="w-[90px] group h-[90px] rounded-[16px] flex justify-center relative">
                        <img alt="" className="w-[90px] h-[90px] rounded-[16px]" src={photoRight} />
                    </div>
                    :
                    <div className="bg-[#b6b9c2] rounded-[16px] w-[90px] h-[90px] flex justify-center items-center" onClick={openPhotoLoadingModalRight}>
                        <PlusIconWhite />
                    </div>}

            </div>

            {!(photoSrc && photoLeft && photoRight) && <div className="mt-[10px]  text-[#242a46] text-[14px] opacity-80 text-center leading-[1.29]">
                For a more accurate and comprehensive beauty
                <br />
                assessment, please upload additional selfies
            </div>}
        </>
        }

        <div className="max-w-[260px] text-[#242a46] mt-[48px] text-base leading-[normal] font-bold flex justify-center text-center tracking-[normal]">
            {renderWithLineBreaks(t('SCORE_RATINGS_TITLE'))}
        </div>
        {/* <div className="w-[291px] mt-[30px] px-[30px] py-[25px] bg-[#242a46] flex rounded-[16px]">
            <div className="flex self-center">
                <div className="w-[100px]">
                    <div className="text-[16px] w-full text-center text-[#fff]">
                        {renderWithLineBreaks(t('RESULT_SCORE_TITLE'))}
                    </div>
                    <div className="relative mt-[6px] flex justify-center items-center w-[80px] h-[80px]">
                        <CircleProgressBar className="absolute top-0 left-0" score={score} />
                        <div className="text-[30px] text-[#fff] font-bold">
                            {score}
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-[100px] ml-[32px]">
                <div className="text-[16px] w-full text-center text-[#fff]">
                    {renderWithLineBreaks(t('RESULT_POTENTIAL_TITLE'))}
                </div>
                <div className="relative mt-[6px] flex justify-center items-center w-[80px] h-[80px]">
                    <CircleProgressBar className="absolute top-0 left-0" score={potential} />
                    <div className="text-[30px] text-[#fff] font-bold">
                        {potential}
                    </div>
                </div>
            </div>
        </div> */}

        {/* <div className="max-w-[260px] text-[#242a46] mt-[40px] text-base leading-[normal] font-bold flex justify-center text-center">
            {renderWithLineBreaks(t('SCORE_RATINGS_TITLE'))}
        </div> */}
        <ScoreDisplay
            ratings={ratings}
            onClick={onGetFullAnalysis}
        />

        <ButtonElem
            onClick={onGetFullAnalysis}
            text={renderWithLineBreaks(t('SCORE_RATINGS_BUTTON'))}
            helpText={renderWithLineBreaks(t('SCORE_RATINGS_NOTICE'))}
        />

        <FeaturesBlock
            isAuthorSession={isAuthorSession}
            features={features}
            imageSrc={image}
            faceShape={faceShape}
            onClickFullProfile={onGetFullProfile}
        />
        {
            !hideCharacter && <>
                <div className="max-w-[260px] text-[#242a46] mt-[38px] text-base leading-[normal] font-bold flex justify-center text-center tracking-[normal]">
                    {renderWithLineBreaks(t('SCORE_IMAGE_TITLE'))}
                </div>

                <div onClick={onReload} className="mt-[30px] w-[290px] relative h-[290px] rounded-[16px] bg-[rgba(36,42,70,0.1)] flex justify-center overflow-hidden" >
                    {
                        (isCharacterLoading && !characterUrl && !timeoutFallenFetch) && <div className="self-center w-[160px] relative left-[-2px] top-[14px]" >
                            <Lottie options={defaultLottieOptions}
                                height={90}
                                width={160}
                            />
                            <div className="h-[24px] text-[18px] mt-[4px] font-header font-bold color-[#000000] text-center">{characterProgress}</div>
                        </div>
                    }
                    {
                        (!isCharacterLoading && characterUrl) && <>
                            <button onClick={onDownloadImage} className={`bg-[rgba(0,0,0,0.3)] items-center justify-center rounded-[50%] flex w-[44px] h-[44px] absolute top-[10px] right-[10px]`}>
                                <DownloadIcon />
                            </button>
                            <img src={characterUrl} className="w-full h-full" alt="" />
                        </>
                    }
                    {
                        (!characterUrl && timeoutFallenFetch) && <div className="self-center cursor-pointer mt-[23px]" >
                            <ReloadIcon />
                            <div className="text-[18px] mt-[19px] font-header font-bold text-[#595a5b] text-center">{renderWithLineBreaks(t('RELOAD'))}</div>
                        </div>
                    }
                </div>

                {(isAuthorSession && showButtonTips) && <ButtonElem
                    onClick={onGetTips}
                    text={renderWithLineBreaks(t('SCORE_IMAGE_BUTTON'))}
                    helpText={renderWithLineBreaks(t('SCORE_IMAGE_NOTICE'))}
                />}

            </>
        }
        {!isPreview && <>

            {isAuthorSession ?
                <>
                    {!!title && <div className="mt-[40px] text-[#242a46] text-center text-[16px] font-bold tracking-[normal]">
                        {title}
                    </div>}
                    {showBlock && <div
                        className={`${!!title ? 'mt-[20px]' : 'mt-[40px]'} pt-[18px] pb-[19px] flex flex-col gap-[21px] justify-center w-full max-w-[290px] bg-[#fff] rounded-[16px] shadow-[0_0_2px_0_rgba(0,0,0,0.16),0_2px_12px_0_rgba(0,0,0,0.12)]`}
                    >
                        <div className="text-[18px] text-[#232948] font-bold text-center leading-[1.33] tracking-[0.56px] flex justify-center items-center">
                            {renderWithLineBreaks(caption)}
                        </div>
                        <button onClick={runCompatibility} className="self-center cursor-pointer text-[#0076ff] border-solid border-[#0076ff] text-[17px] font-bold border-[3px] w-[150px] flex justify-center items-center rounded-[22px] h-[38px] ">
                            TRY
                        </button>
                    </div>}
                </>
                :
                <ReferalBlock />
            }

            <div ref={renderedResult} className="absolute left-[-1500px] w-[335px] h-[430px] bg-[#242a46] flex flex-col pt-[55px]">
                <img
                    alt=""
                    src={image}
                    className="self-center bg-cover border border-solid font-[15px] border-[#6c7183] w-[150px] h-[150px] rounded-[50%] bg-no-repeat bg-center"
                />
                <div className="flex justify-center mt-[30px]">
                    <SpeedometerCombined value={score || 50} />
                </div>
                <div className="flex justify-center mt-[21px]">
                    {status && (<div style={{ backgroundColor: colorOfScore.bg, color: colorOfScore.text }} className="text-[15px] h-[28px] rounded flex justify-center items-center px-3 text-white">
                        {status}
                    </div>)}
                </div>
            </div>

            <TipsModal opened={opened} onClose={closeModal} />
            <FeedbackModal opened={FeedbackModalOpened} onClose={closeFeedbackModal} />
        </>}

        {(showReport && isAuthorSession) && <div className="bg-[#811883] rounded-[16px] mt-[40px] w-full max-w-[290px] pb-[29px] flex flex-col items-center relative">
            <PDFIcon className="w-[30px] h-[18px] absolute top-[10px] right-[10px]" />

            <img alt="" src={image} className="w-[142px] h-[142px] mt-[30px] border-[2px] rounded-[50%] border-solid border-[#fff]" />
            <div className="text-[28px] font-['Montserrat'] font-bold leading-[normal] text-white mt-[19px] text-center">
                {renderWithLineBreaks(t('SCORE_REPORT_TITLE'))}
            </div>
            <div className="text-white leading-[normal] text-[18px] mt-[12px] text-center">
                {renderWithLineBreaks(t('SCORE_REPORT_DESCRIPTION'))}
            </div>
            <button onClick={onClickInsights} className="bg-[#0076ff] rounded-[16px] flex justify-center items-center mt-[26px] w-[240px] h-[60px] text-[20px] text-white font-bold">
                {renderWithLineBreaks(t('SCORE_REPORT_BUTTON'))}
            </button>
        </div>}

        <InsightsModal opened={openedInsightsModal} onClose={closeInsightModal} image={image} />
        <ModalFullAnalysis opened={fullAnalysisModalOpened} onClose={closeFullAnalysisModal} imageSrc={image} />
        <ModalFullProfile opened={fullProfileModalOpened} onClose={closeFullProfileModal} imageSrc={image} />
        <ModalBeautyTips opened={tipsModalOpened} onClose={closeTipsModal} imageSrc={image} />

        <PhotoLoadingModal opened={photoLoadingModalOpened} onClose={closePhotoLoadingModal} mode={photoLoadingModalMode} scoreId={photoId} />

        <Link to={`/feedback/${photoId}`} className="flex mt-[37px] mb-[74px] text-center text-[12px] text-[#a4a6b2] tracking-[normal]">
            {renderWithLineBreaks(t('COPYRIGHT'))}
        </Link>
    </div >
}
