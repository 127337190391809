import { isAndroid, isTelegram, renderWithLineBreaks } from "../../utils";
import { useTranslation } from "react-i18next";
import { IconGallery } from "../../icons";
import usePhotoLoader from "../../hooks/usePhotoLoader";
import { useCallback, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

export const LoaderPage = ({
    headerText,
    text,
    imageSrc,
}) => {
    const { t } = useTranslation();
    const [direction, setDirection] = useState('unset');

    const onChangeImg = (e) => {
        if (e.target) {
            const img = e.target;
            setDirection(img.height > img.width ? 'height' : 'width');
        }
    }

    return (
        <>
            <div className="text-[25px] font-[600] text-[#232948] leading-[normal]  text-center">
                {renderWithLineBreaks(headerText || '')}
            </div>

            <div className="mt-[21px] w-[241px] h-[346px] overflow-hidden flex justify-center">
                <div className='relative overflow-hidden rounded-[16px]  inline-block self-start'>
                    <img onLoad={onChangeImg} src={imageSrc} alt="" className={twMerge('self-center w-auto h-auto', direction === 'unset' ? '' : `${direction === 'width' ? 'w-[241px] h-[auto]' : 'h-[346px] w-auto'} `)} />
                    <div style={{ isolation: 'isolate' }} className='absolute top-0  left-0 right-0 bottom-0 border-t-solid border-t-[#ff4500] border-t-[2px] bg-[rgba(245,142,66,0.3)] analyzing-animation'>
                    </div>
                </div>
            </div>

            <div className="mt-[12px] text-[#010101] text-center opacity-80 leading-[1.29] text-[14px]">
                {renderWithLineBreaks(text || '')}
            </div>
        </>
    )
}