import { Link } from "react-router-dom/cjs/react-router-dom.min"
import Modal from "../Modal"
import { renderWithLineBreaks } from "../../utils"
import { useTranslation } from "react-i18next"
import iosSrc from '../../images/app-store@3x.webp';
import androidSrc from '../../images/google-play@3x.webp';
import { useCallback } from "react";
import { makeRequest } from "../../utils/request";


export const TipsModal = ({ opened, onClose }) => {
    const { t } = useTranslation();

    const clickAppStore = useCallback(() => {
        makeRequest('/user/update', 'post', {
            tap: 'ios'
        });
    }, []);

    const clickGooglePlay = useCallback(() => {
        makeRequest('/user/update', 'post', {
            tap: 'android'
        });
    }, []);

    return (
        <>
            <Modal isOpen={opened} onClose={onClose} contentClassName="min-h-[468px] relative flex flex-col justify-center">
                <div className="flex flex-col justify-center h-full">
                    <div className="text-[28px] font-bold text-#232948 text-center">
                        {renderWithLineBreaks(t('DIST_TITLE'))}
                    </div>
                    <div className="mt-[20px] px-[20px] text-[18px] text-[#242a46] text-center">
                        {renderWithLineBreaks(t('DIST_SUBTITLE'))}
                    </div>

                    <div className='mt-[30px] flex justify-center gap-[9px] self-center'>
                        <a target='_blank' rel="noreferrer noopener" onClick={clickAppStore} href="https://apps.apple.com/us/app/yasss-anonymous-q-a/id6446479657">
                            <img src={iosSrc} className='w-[120px] h-[41px]' alt="" />
                        </a>

                        <a target='_blank' rel="noreferrer noopener" onClick={clickGooglePlay} href="https://play.google.com/store/apps/details?id=link.yasss.app&hl=en ">
                            <img src={androidSrc} className='w-[120px] h-[41px]' alt="" />
                        </a>
                    </div>
                </div>
                <div className='self-end max-w-[335px] w-full flex justify-center gap-[10px] pt-[23px] absolute bottom-[25px]'>
                    <Link to="/pri" className="font-header text-[13px] text-[#a4a6b2] text-right w-[100px]">{renderWithLineBreaks(t('DIST_PRIVACY'))}</Link>
                    <div className=' w-[0px] h-[20px] self-center border-solid border-l border-[#dde0eb]' />
                    <Link to="/ter" className=" self-center text-[#a4a6b2] w-[100px] text-left font-header text-[13px] ">{renderWithLineBreaks(t('DIST_TERMS'))}</Link>
                </div>
            </Modal>
        </>
    )

}