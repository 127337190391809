import { useEffect } from "react";
import { useErrorText, useSetPaywallMethod } from "../context";
import { isTelegram } from "../utils";

export const ErrorPage = () => {

    const setPaywallMethod = useSetPaywallMethod();

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', isTelegram() ? '#FFFFFF' : '#f6f7f8');
        setPaywallMethod('none');
    }, [setPaywallMethod])

    const errorText = useErrorText()
    return (
        <div className={`${isTelegram() ? 'bg-[#FFFFFF]' : 'bg-[#f6f7f8]'}  flex items-center justify-center`}>
            <div className="max-w-[375px] h-[98vh] flex flex-col items-center justify-center">
                <div className="font-header text-[18px] font-[600] max-w-[335px] color-black text-center">
                    {errorText || 'No Internet Connection'}
                </div>
                <button className=" mt-[24px] font-[600] cursor-pointer border-4 border-[#0076ff] rounded-[30px] border-solid w-[151px] h-[38px] flex justify-center items-center text-[#0076ff]">
                    <a href="/">Try again</a>
                </button>
            </div>
        </div>
    )
}