import { useTranslation } from "react-i18next"
import Modal from "../Modal"
import { renderWithLineBreaks } from "../../utils";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import { makeRequest } from "../../utils/request";

export const ModalFullAnalysis = ({ opened, onClose, imageSrc }) => {
    const { t } = useTranslation();

    const onClickBuy = useCallback(() => {
        makeRequest('/user/update', 'post', {
            tap: 'report'
        });
        alert(`This method isn't implemented yet`);
        window.dataLayer.push({ 'event': 'unlock' });
        onClose();
    }, [onClose]);

    return (
        <Modal isOpen={opened} onClose={onClose} contentClassName="min-h-[472px] relative flex flex-col items-center">
            <img src={imageSrc} alt="" className="rounded-[50%] w-[142px] h-[142px] mt-[42px] self-center border-[1px] border-[#b6b9c2] border-solid" />

            <div className="mt-[27px] text-center text-[22px] text-[#242a46] font-[600] leading-[1.05]">
                {renderWithLineBreaks(t('SCORE_RATINGS_REPORT_TITLE'))}
            </div>

            <div className="mt-[11px] text-center text-[14px] w-[252px] font-[400] leading-[1.29] text-[#242a46]">
                {renderWithLineBreaks(t('SCORE_RATINGS_REPORT_DESCRIPTION'))}
            </div>

            <button onClick={onClickBuy} className="mt-[18px] w-[241px] h-[64px] flex text-white text-[20px] font-bold bg-[#0076ff] rounded-[16px] justify-center items-center">
                {renderWithLineBreaks(t('SCORE_RATINGS_REPORT_BUTTON'))}
            </button>
            <div className="mt-[11px] text-[14px] text-[#242a46] font-[400] opacity-80 leading-[1.29] text-center">
                {renderWithLineBreaks(t('SCORE_RATINGS_REPORT_NOTICE'))}
            </div>
        </Modal>
    )
}