import { twMerge } from "tailwind-merge"
import CircleProgressBar from "../ProgressBar"

export const ScoreBlockElement = ({ bgColor, mainColor, secondColor, textColor, score, title, smallMode, blur = false }) => {
    return (
        <div className={twMerge('w-[140px] h-[140px] rounded-[16px] flex justify-center', smallMode ? 'w-[116px] h-[116px]' : '')} style={{ backgroundColor: bgColor }} key={title}>
            <div className="flex self-center">
                <div className="w-full flex flex-col">
                    <div className={twMerge("text-[16px] w-full leading-[19px] text-center font-[600]", blur ? 'blur-[8px]' : '')} style={{ color: textColor }}>
                        {title}
                    </div>
                    <div className={twMerge("relative mt-[8px] flex justify-center self-center items-center w-[80px] h-[80px]", blur ? 'blur-[8px]' : '', smallMode ? 'w-[66px] h-[66px] ' : '')}>
                        <CircleProgressBar smallMode={smallMode} className="absolute top-0 left-0" score={score} mainColor={mainColor} secondColor={secondColor} />
                        <div className="text-[30px] font-bold" style={{ color: textColor }}>
                            {score}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}