import { useCallback } from "react";
import Modal from "../Modal";
import { LoadingIcon } from "../../icons";
import "../../styles/Animation.css";
import personSlideSrc from '../../images/square-selfie-slide.webp';
import { PhotoLoading } from "./PhotoLoading";
import { useTranslation } from "react-i18next";
import { PhotoLoadingError } from "./PhotoError";
import { ScorePreview } from "./ScorePreview";
import {
    useGetStage,
    usePhotoState,
    useReset,
} from "../../context/PhotoLoadingContext";
import { Paywall } from "./Paywall";
import { Auth } from "./Auth";
import { renderWithLineBreaks } from "../../utils";
import { LoaderPage } from "./LoaderPage";

export const MainPhotoLoadingModal = ({ opened, onClose }) => {
    const { t } = useTranslation();
    const state = usePhotoState();
    const getStage = useGetStage();


    const reset = useReset();

    const closeHandler = useCallback(() => {
        reset();
        onClose();
    }, [onClose, reset])

    const stage = getStage(state);

    const renderContent = (state) => {
        const stage = getStage(state);

        switch (stage) {
            case 'loading':
                const headerText = state.loadingHeader;
                const text = state.loadingText;
                const image = state.loadingSrc;
                return (
                    <LoaderPage imageSrc={image} text={text} headerText={headerText} />
                )
            case 'fullFaceError':
                const onLoadFFError = state.photoFullFaceError.onLoad
                return (
                    <PhotoLoadingError onPhotoLoaded={onLoadFFError} text={state.photoFullFaceError.text} exampleSrc={state.photoFullFaceError.url} />
                )
            case 'profileError':
                const onLoadPE = state.photoProfileError.onLoad
                return (
                    <PhotoLoadingError onPhotoLoaded={onLoadPE} text={state.photoProfileError.text} exampleSrc={state.photoProfileError.url} />
                )
            case 'profileLoad':
                const onLoadP = state.profileLoad.onLoad
                return (
                    <PhotoLoading onPhotoLoaded={onLoadP} text={t('UPLOAD_SELFIE_3_STEP_1_TITLE')} exampleSrc={personSlideSrc} />
                )
            case 'preview':
                const onGoFromExpress = state.onGoFromExpress;
                return (
                    <ScorePreview scoreId={state.scoreId} image={state.previewData.previewImage} texts={state.previewData.texts} onClickGetScore={onGoFromExpress} score={state.previewData.score} status={state.previewData.status} />
                )
            case 'paywall':
                const onGoPay = state.onGoPay;
                return (
                    <Paywall onClickContinue={onGoPay} />
                )
            case 'auth':
                const goFromAuth = state.onGetEmail
                return (
                    <Auth onGetEmail={goFromAuth} />
                )
            default:
                return (
                    <div className="self-center">
                        <LoadingIcon className="loader" />
                    </div>
                )
        }
    }

    return (
        <Modal shouldCloseOnOutline={false} isOpen={opened} onClose={closeHandler} hideCloseButton={stage === 'loading'} contentClassName="min-h-[517px] relative overflow-hidden">
            <div className="flex w-full justify-center">
                <div className="w-[241px] flex flex-col pt-[40px] items-center pb-[30px]">
                    {renderContent(state)}
                </div>
            </div>
        </Modal>
    )
}