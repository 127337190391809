import { Link } from "react-router-dom/cjs/react-router-dom.min"
import Modal from "../Modal"
import { renderWithLineBreaks } from "../../utils"
import { useTranslation } from "react-i18next"
import iosSrc from '../../images/app-store@3x.webp';
import androidSrc from '../../images/google-play@3x.webp';
import { useCallback } from "react";
import { makeRequest } from "../../utils/request";
import { PDFIcon } from "../../icons";


export const InsightsModal = ({ opened, onClose, image }) => {
    const { t } = useTranslation();

    const onClickInsights = useCallback(() => {
        makeRequest('/user/update', 'post', {
            tap: 'report'
        });
        alert(`This method isn't implemented yet`);
        onClose();
    }, [onClose]);


    return (
        <>
            <Modal hideCloseButton={true} isOpen={opened} onClose={onClose} contentClassName="h-[350px] max-w-[290px] rounded-[16px] relative flex flex-col items-center">
                <PDFIcon className="w-[30px] h-[18px] absolute top-[10px] right-[10px]" />
                <img alt="" src={image} className="w-[94px] h-[94px] border-[2px] mt-[34px] rounded-[50%] border-solid border-[#242a46]" />
                <div className="text-[28px] font-['Montserrat'] font-bold leading-[normal] text-[#242a46] mt-[9px] text-center">
                    {renderWithLineBreaks(t('SCORE_REPORT_TITLE'))}
                </div>
                <div className="text-[#242a46] leading-[normal] text-[18px] mt-[12px] text-center">
                    {renderWithLineBreaks(t('SCORE_REPORT_DESCRIPTION'))}
                </div>
                <button onClick={onClickInsights} className="bg-[#0076ff] rounded-[16px] flex justify-center items-center mt-[26px] w-[240px] h-[60px] text-[20px] text-white font-bold">
                    {renderWithLineBreaks(t('SCORE_REPORT_BUTTON'))}
                </button>

            </Modal>
        </>
    )

}