import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Result } from "../components/Result/ResultPage";
import { Loader } from "../components/Loader";
import { isTelegram, loadCharacter, loadResultScore } from "../utils";
import { useHistory } from "react-router-dom/";
import { usePaywallMethod, useSetPaywallMethod, useShowError, useShowPotential, useTimerDelay } from "../context";
import { getUdid } from "../utils/request";
import { useTranslation } from "react-i18next";


let settings = {
    showPotential: false
}

export const ResultPage = () => {

    const history = useHistory();

    let { photoId } = useParams();
    const alreadyLoading = useRef()
    const [isLoading, setIsLoading] = useState(true);
    const [isCharacterLoading, setIsCharacterLoading] = useState(true);
    const [characterCaption, setCharacterCaption] = useState('');
    const [characterUrl, setCharacterUrl] = useState('');
    const [result, setResult] = useState(null)
    const paywallMethod = usePaywallMethod();
    const showError = useShowError();
    const [isPreview, setIsPreview] = useState(true);
    const [hideCharacter, setHideCharacter] = useState(false);
    const showPotential = useShowPotential();
    settings.showPotential = showPotential;

    const [characterProgress, setCharacterProgress] = useState('')
    const setPaywallMethod = useSetPaywallMethod();

    const [timeoutFallenFetch, setTimeoutFallenFetch] = useState(false);
    const timerDelay = useTimerDelay();
    const { t } = useTranslation();

    const onGoAnalysis = useCallback(() => {
        history.push(`/analysis/${photoId}`);
    }, [photoId, history])

    const setSelectedTimeout = useCallback(() => {
        setTimeoutFallenFetch(true)
    }, [])

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', isTelegram() ? '#FFFFFF' : '#f6f7f8');
    }, [])

    useEffect(() => {
        if (result?.selfie_url) {
            const description = document.querySelector(`meta[property='og:description']`);
            description.setAttribute('content', 'Check Out How the AI Rates Me!');

            const imageUrlMeta = document.querySelector(`meta[property='og:image']`);
            imageUrlMeta.setAttribute('content', result?.selfie_url);

        }
    }, [result?.selfie_url])

    const loadResult = useCallback(async () => {
        loadResultScore(photoId, paywallMethod).then((result) => {
            if (result.data.status) {
                setResult(result.data.result)
                setCharacterCaption(result.data.result?.image?.caption || '')
                setIsLoading(false)
                alreadyLoading.current = false;

                if (result.data.result.is_preview === 1) {
                    setIsPreview(true);
                } else {
                    setIsPreview(false);
                }

                if (result.data.result.image === undefined || !settings.showPotential) {
                    setHideCharacter(true);
                    return;
                }

                if (result.data.result.image.url) {
                    setIsCharacterLoading(false);
                    setCharacterUrl(result.data.result.image.url);
                } else {
                    loadCharacter(photoId, setCharacterProgress, setSelectedTimeout, timerDelay).then((result,) => {
                        if (!result) {
                            return;
                        }
                        if (result?.data?.status) {
                            setIsCharacterLoading(false);
                            setCharacterUrl(result.data.result.url);
                            setHideCharacter(false);
                            setPaywallMethod('none');
                        } else {
                            showError(result.data.message || 'No Internet Connection');
                            console.error('error', result);
                            history.push('/err');
                        }
                    })
                }

            } else {
                console.error('error', result.data)
                showError(result.data.message || 'No Internet Connection');
                history.push('/err');
            }
        }).catch((e) => {
            console.error('result err', e)
            history.push('/err');
            setIsLoading(false);
        })
    }, [photoId, history, paywallMethod, setPaywallMethod, showError, timerDelay, setSelectedTimeout])

    useEffect(() => {
        if (photoId && paywallMethod) {
            if (alreadyLoading.current === true) {
                return;
            }
            alreadyLoading.current = true;
            loadResult();
        }
    }, [loadResult, paywallMethod, photoId, showPotential])


    const ratings = useMemo(() => {
        if (!result) {
            return []
        }
        const ratings = result.ratings;
        const items = ratings.slice(0, 4);

        return { items };
    }, [result])

    const features = useMemo(() => {
        if (!result || !result.features) {
            return []
        }

        const features = result.features;
        const items = features.slice(0, 4);
        return { items };
    }, [result])

    if (!isLoading && !result) {
        return <div>error loading</div>
    }

    return <>
        {isLoading ? <div>
            <Loader headerText={t('LOADING_SCORE_ADVANCED_TITLE')} text={t('LOADING_SCORE_ADVANCED_SUBTITLE')} />
        </div> : <Result
            hideCharacter={hideCharacter || (!showPotential)}
            timeoutFallenFetch={timeoutFallenFetch}
            characterProgress={characterProgress}
            characterUrl={characterUrl}
            characterCaption={characterCaption}
            isCharacterLoading={isCharacterLoading}
            photoSrc={result.selfie_url}
            photoLeft={result.selfie_side_left_url}
            photoRight={result.selfie_side_right_url}
            score={result.score}
            status={result.status}
            reviews={result.reviews}
            isAuthorSession={result.udid === getUdid()}
            photoId={photoId}
            onGoAnalysis={onGoAnalysis}
            isPreview={isPreview}
            potential={result.score_potential}
            features={features.items}
            ratings={ratings.items}
            faceShape={result.features_face_shape}
        />}
    </>
}
